import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from 'react'
import styled from 'styled-components'
import axios from 'axios'
import AiCard from './AiCard'

const NewCuration = forwardRef((props, ref) => {
  const { version, getVersion, page } = props

  const [data, setData] = useState([])
  const [type, setType] = useState('')
  const [title, setTitle] = useState('')
  const [filter, setFilter] = useState('')
  const [checked, setChecked] = useState([])

  const dragDataInit = {
    target: null,
    index: -1,
    move_up: [],
    move_down: [],
    updateList: [],
  }

  const [drag, setDrag] = useState(false)
  const [dragData, setDragData] = useState(dragDataInit)
  const [isScroll, setIsScroll] = useState('')
  const [posY, setPosY] = useState(0)

  const typeList = ['', 'card']
  const filterList = ['all', 'female', 'male']

  const checkRef = useRef([])
  const wrapperRef = useRef()

  useEffect(() => {
    const scrollHeight = wrapperRef.current.scrollTop
    const posH = wrapperRef.current.getBoundingClientRect().height

    if (isScroll === 'down') {
      wrapperRef.current.scrollTo({
        top: scrollHeight + posH - posY * 2,
        behavior: 'smooth',
      })
    }

    if (isScroll === 'up') {
      wrapperRef.current.scrollTo({
        top: scrollHeight - posH + posY * 2,
        behavior: 'smooth',
      })
    }
  }, [isScroll])

  useEffect(() => {
    if (type === 'card') {
      setData([
        {
          seq: uuidv4(),
          assistantId: '',
        },
      ])
    }
  }, [type])

  useEffect(() => {
    console.log(checked)
    console.log(checkRef.current)
  }, [checked])

  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  }

  const clickNew = async () => {
    const params = {
      versionId: version.versionId,
      page,
      type,
      title,
      filter,
      assistantList: JSON.stringify(data)
    }

    await axios.post(`/new/webAdmin/v2/royal/collection`, params, {
      headers: {
        token: sessionStorage.getItem('token'),
      }
    }).then(() => {
      getVersion(version.versionId, false)
    })
  }

  const clickAdd = async () => {
    if (!type) {
      alert('type을 선택해주세요')
      return
    }
    const temp = [...data]
    temp.unshift({
      seq: uuidv4(),
      assistantId: '',
    })

    setData(temp)
  }

  const clickDelete = async () => {
    if (checked.length > 0) {
      setData(data.filter(d => !checked.includes(String(d.seq))))
    }

    if (checkRef.current) {
      checkRef.current.forEach((c, i) => {
        if (c && c.checked) {
          c.checked = false
        }
      })
    }

    setChecked([])
  }

  const assistantPreview = item => {
    axios
      .get(`/new/webAdmin/v2/royal/assistant/${item.assistantId}`, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(res => {
        const result = res.data.result

        if (result) {
          setData(
            data.map(d =>
              d.assistantId === item.assistantId
                ? {
                    ...d,
                    assistantProfileImg:
                      result.assistantProfileImg ||
                      'https://img.stipop.io/image/stipop/icon/chat/chat-create-profile-icon.png',
                  }
                : d
            )
          )
        }
      })
  }

  const handleCheck = e => {
    if (e.target.checked) {
      setChecked(checked.concat(e.target.value.split(',')))
    } else {
      setChecked(seq => e.target.value.split(',').indexOf(seq) < 0)
    }
  }

  const _dragStart = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    setDragData({
      ...dragData,
      target: e.currentTarget,
      index: Number(e.currentTarget.dataset.index),
      updateList: [...data],
    })

    e.dataTransfer.setData('text/html', '')
    e.dataTransfer.effectAllowed = 'move'
  }

  const _dragEnter = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    const _dragged = Number(dragData.target.dataset.index)
    const _target = Number(e.currentTarget.dataset.index)
    let move_down = [...dragData.move_down]
    let move_up = [...dragData.move_up]

    if (_dragged > _target) {
      if (move_down.includes(_target)) {
        move_down = move_down.slice(0, move_down.indexOf(_target))
      } else {
        move_down = Array(_dragged - _target)
          .fill()
          .map((v, i) => i + _target)
          .reverse()
      }
      move_up = []
    } else if (_dragged < _target) {
      if (move_up.includes(_target)) {
        move_up = move_up.slice(0, move_up.indexOf(_target))
      } else {
        move_up = Array(_target - _dragged)
          .fill()
          .map((v, i) => _target - i)
          .reverse()
      }
      move_down = []
    } else {
      move_down = []
      move_up = []
    }

    setDragData({
      ...dragData,
      // updateList: updateData,
      index: _target,
      move_up,
      move_down,
    })
  }

  const _dragLeave = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    if (e.currentTarget === dragData.target) {
      e.currentTarget.style.visibility = 'hidden'
    }
  }

  const _dragEnd = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    let updateData = [...dragData.updateList]

    const changeOrder = (i, t) => {
      const target = updateData.splice(i, 1)[0]
      updateData.splice(t, 0, target)
    }

    const _dragged = Number(e.currentTarget.dataset.index)
    let _target = Number(dragData.index)

    if (dragData.move_up.length > 0) {
      _target = dragData.move_up[dragData.move_up.length - 1]
    } else if (dragData.move_down.length > 0) {
      _target = dragData.move_down[dragData.move_down.length - 1]
    } else {
      _target = _dragged
    }

    changeOrder(_dragged, _target)

    setDrag(false)
    setData(updateData)
    setDragData({
      ...dragData,
      move_down: [],
      move_up: [],
      updateList: [],
    })

    e.currentTarget.style.visibility = 'visible'
    e.dataTransfer.dropEffect = 'move'
  }

  const _dragCapture = async e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    const posH = e.target.getBoundingClientRect().height
    const bottom = wrapperRef.current.getBoundingClientRect().bottom
    const top = wrapperRef.current.getBoundingClientRect().top

    setPosY(posH)

    if (e.clientY && e.clientY > top && e.clientY < bottom) {
      if (bottom - e.clientY < 90) {
        setIsScroll('down')
      } else if (e.clientY - top < 90) {
        setIsScroll('up')
      } else {
        setIsScroll('')
      }
    }
  }

  useImperativeHandle(ref, () => ({
    clickNew,
    clickAdd,
    clickDelete,
  }))

  return (
    <Wrapper ref={wrapperRef}>
      <Title>Type</Title>
      <div style={{ width: '100px', height: '30px' }}>
        <select
          value={type}
          onChange={e => setType(e.target.value)}
          style={{ width: '100%', height: '100%' }}
        >
          {typeList.map((item, i) => (
            <option key={i} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <Title>Title</Title>
      <TitleInput
        type={'text'}
        value={title}
        onChange={e => {
          setTitle(e.target.value)
        }}
      />
      <Title>Filter</Title>
      <div style={{ width: '100px', height: '30px' }}>
        <select
          value={filter}
          onChange={e => setFilter(e.target.value)}
          style={{ width: '100%', height: '100%' }}
        >
          {filterList.map((item, i) => (
            <option key={i} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      {data.map((item, index) => {
        let default_class = ''
        dragData.move_down.includes(index) && (default_class = 'move_down')
        dragData.move_up.includes(index) && (default_class = 'move_up')
        return (
          <CurationItem
            key={index}
            data={item}
            data-index={index}
            draggable={drag}
            onDragStart={_dragStart}
            onDragEnter={_dragEnter}
            onDragLeave={_dragLeave}
            onDragEnd={_dragEnd}
            onDragCapture={_dragCapture}
            className={default_class}
          >
            <AiCard
              key={index}
              ref={{ checkRef }}
              item={item}
              index={index}
              handleCheck={handleCheck}
              data={data}
              setData={setData}
              setDrag={setDrag}
              assistantPreview={assistantPreview}
            />
          </CurationItem>
        )
      })}
    </Wrapper>
  )
})

export default NewCuration

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  row-gap: 10px;
  z-index: 2;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

const CurationItem = styled.div`
  width: 100%;
  min-width: 400px;
  height: 80px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  padding: 0 15px;
  position: relative;
  opacity: 0.999;

  #drag {
    width: 25px;
    height: 20px;
    cursor: pointer;
  }

  &.move_up {
    transform: translateY(-90px);
    z-index: 1;
  }
  &.move_down {
    transform: translateY(90px);
    z-index: 1;
  }
`

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`

const TitleInput = styled.input`
  width: 100%;
  height: 30px;
`
