import React, { useState, useEffect, useRef } from 'react'
import { Container } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import Loading from '../Common/Loading/Loading'

import ContentsListTopBar from './ContentsListTopBar'
import ContentsListBottomBar from './ContentsListBottomBar'
import ContentsListTable from './ContentsListTable'
import ContentsInfoModal from './ContentsInfoModal'
import styled from 'styled-components'

function ContentsList(props) {
  const pageName = props.q
    ? props.q === 'ai'
      ? 'AI/ContentsList'
      : props.q === 'premium'
      ? 'Premium/ContentsList'
      : 'ContentsList'
    : 'ContentsList'
  const [show, setShow] = useState(false) // Modal 활성 여부
  const [isLoading, setIsLoading] = useState(true) // 화면 로딩 여부

  // 조회하는 목록 조건 초기화
  const [params, setParams] = useState({
    pageNumber: props.pageNumber ? props.pageNumber : 1,
    search: props.search ? props.search : '',
    type: props.type ? props.type : '',
    order: props.order ? props.order : '',
    language: props.language ? props.language : '',
    q: props.q ? props.q : 'studio',
  })

  const [packages, setPackages] = useState([]) // 스티커팩 목록에 필요한 정보
  const [pageMap, setPageMap] = useState('') // 페이지를 메기기 위한 정보

  const [selectedPackages, setSelectedPackages] = useState([{}]) // 체크 표시된 스티커팩&스티커 정보 (CSV 추출)

  const [languages, setLanguages] = useState([]) // 전체 언어 목록
  const [guidelines, setGuidelines] = useState([]) // 스티커팩 수정 가이드라인 목록
  const [libraries, setLibraries] = useState([]) // 스티커팩을 담는 라이브러리 목록
  const [categories, setCategories] = useState([]) // 전체 카테고리 목록
  const [selfServingGroups, setSelfServingGroups] = useState([]) // self-serving 그룹 목록
  const [selfServingGroupsOfThePackage, setSelfServingGroupsOfThePackage] =
    useState([]) // self-serving 그룹 목록

  // Modal 에서 사용할 정보들
  let countSetInfo = 0 // 받아온 정보
  const countInfo = 2 // 서버에서 받아올 정보 2개 (guidelines, librariesAndSelfServingGroups, )
  const [modalPackage, setModalPackage] = useState({}) // Modal에서 보이는 Package 정보
  const [librariesOfThePackage, setLibrariesOfThePackage] = useState([]) // modalPackage의 채널사(libraries) 목록
  const [selectedCategory, setSelectedCategory] = useState([]) // modalPackage의 self-serving 그룹 목록
  const [appCategory, setAppCategory] = useState([]) // modalPackage의 self-serving 그룹 목록
  const [region, setRegion] = useState([])
  const [selectedRegion, setSelectedRegion] = useState([])
  const [selectedLang, setSelectedLang] = useState(0)
  const [guidelinesOfThePackage, setGuidelinesOfThePackage] = useState([]) // modalPackage의 수정 요청 사유(guidelines) 목록

  const [order, setOrder] = useState('')
  const insertStickerTagRef = useRef()

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      getPackages()
    } else {
      props.history.push('/')
    }
  }, [params, order])

  const getPackages = () => {
    setIsLoading(true)
    const queryString =
      '?pageNumber=' +
      params.pageNumber +
      '&search=' +
      encodeURIComponent(params.search) +
      '&type=' +
      params.type +
      '&order=' +
      order +
      '&language=' +
      params.language +
      '&q=' +
      params.q
    console.log('queryString=', queryString)

    fetch('/new/webAdmin/v2/contents' + queryString, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'get',
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 'fail' && responseJson.code === '1111')
          props.history.push('/')
        setPageMap(responseJson.pageMap)
        setPackages(responseJson.packageList)
        setLanguages(responseJson.language)
        setGuidelines(responseJson.guideList)
        setCategories(responseJson.categoryList)

        setLibraries(
          responseJson.group.filter((item) => item.title !== 'Self Serving')
        )
        setSelfServingGroups(
          responseJson.group.filter((item) => item.title === 'Self Serving')
        )

        if (nextIdx === 25) {
          handleShow(responseJson.packageList[0])
        } else if (nextIdx === -1) {
          handleShow(responseJson.packageList[24])
        }

        props.history.push(
          `/${pageName}?search=${encodeURIComponent(params.search)}&type=${
            params.type
          }&order=${params.order}&language=${
            params.language
          }&pageNumber=${Number(params.pageNumber)}`
        )

        setIsLoading(false)
      })
      .catch((error) => {
        console.error('123=' + error)
      })
  }

  const getGuidelinesOfThePackage = (pid) => {
    fetch('/new/webAdmin/v2/reason?pid=' + pid, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'get',
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 'fail' && responseJson.code === '1111')
          props.history.push('/')
        setGuidelinesOfThePackage(responseJson.result)
        if (++countSetInfo === countInfo) setShow(true)
      })
      .catch((error) => {
        console.error('123=' + error)
      })
  }

  const getLibrariesAndSelfServingGroupOfThePackage = (pid) => {
    fetch('/new/webAdmin/v2/package/group?pid=' + pid, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'get',
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 'fail' && responseJson.code === '1111')
          props.history.push('/')
        setLibrariesOfThePackage(
          responseJson.result.filter((item) => item.title !== 'Self Serving')
        )
        setSelfServingGroupsOfThePackage(
          responseJson.result.filter((item) => item.title === 'Self Serving')
        )
        if (++countSetInfo === countInfo) setShow(true)
      })
      .catch((error) => {
        console.error('123=' + error)
      })
  }

  const getAppCategory = async (p_id) => {
    const response = await fetch(
      '/new/webAdmin/v2/package/category?p_id=' + p_id,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem('token'),
        },
        method: 'get',
      }
    )

    const responseJson = await response.json()
    if (responseJson.status === 'fail' && responseJson.code === '1111')
      props.history.push('/')
    // console.log(responseJson.body.category)

    setSelectedCategory(responseJson.body.category)
    setAppCategory(responseJson.body.appCategory)

    if (++countSetInfo === countInfo) setShow(true)
  }

  const getRegion = async (p_id) => {
    const response = await fetch(
      '/new/webAdmin/v2/package/region?p_id=' + p_id,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem('token'),
        },
        method: 'get',
      }
    ).catch((error) => {
      console.error('123=' + error)
    })

    const responseJson = await response.json()
    if (responseJson.status === 'fail' && responseJson.code === '1111')
      props.history.push('/')

    setSelectedLang(responseJson.body.selectedLang)
    setSelectedRegion(responseJson.body.selectedRegion)
    setRegion(responseJson.body.region)

    if (++countSetInfo === countInfo) setShow(true)
  }

  // ContentsListTable 에서 modal 띄울 때
  const handleShow = async (data) => {
    countSetInfo = 0
    getGuidelinesOfThePackage(data.p_id)
    getLibrariesAndSelfServingGroupOfThePackage(data.p_id)
    // getAppCategory(data.p_id);
    // getRegion(data.p_id);
    getRegion(data.p_id)
    setModalPackage(data)
  }

  // ContentsListTable 에서 checkbox onClick 처리
  const handleClick = (event) => {
    const data = JSON.parse(event.target.value)
    const { p_id, p_name, u_id, u_name, stickers } = data

    if (event.target.checked) {
      let stickerList = stickers.map((sticker) => ({
        p_id: p_id,
        p_name: p_name,
        u_id: u_id,
        u_name: u_name,
        s_id: sticker.s_id,
        s_img: sticker.s_img,
      }))
      setSelectedPackages(selectedPackages.concat(stickerList))
    } else {
      setSelectedPackages(selectedPackages.filter((item) => item.p_id !== p_id))
    }
  }

  const updatePackageBookmark = (seq, value) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, p_bookmark: value ? 'Y' : 'N' } : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq ? { ...item, p_bookmark: value ? 'Y' : 'N' } : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updatePackagePremium = (seq, value) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq
          ? { ...item, artiSubPackageYn: value ? 'Y' : 'N' }
          : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq
            ? { ...item, artiSubPackageYn: value ? 'Y' : 'N' }
            : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateIsUS = (seq, value) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, isUS: value ? 'Y' : 'N' } : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq ? { ...item, isUS: value ? 'Y' : 'N' } : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updatePackageTitles = (seq, titles) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, titleList: titles } : item
      )
    )
  }

  const updatePackageName = (seq, name) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, p_name: name } : item
      )
    )
    handleShow(
      packages
        .map((item) => (item.p_id === seq ? { ...item, p_name: name } : item))
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateLang = (seq, lang) => {
    const langName = languages.filter((l) => l.seq === Number(lang))[0].langName
    setPackages(
      packages.map((item) =>
        item.p_id === seq
          ? { ...item, langName: langName, langSeq: lang }
          : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq
            ? { ...item, langName: langName, langSeq: lang }
            : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateCharacterName = (seq, name) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, p_charName: name } : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq ? { ...item, p_charName: name } : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateDescription = (seq, desc) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, p_description: desc } : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq ? { ...item, p_description: desc } : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateCategory = (seq, category) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, p_category: category } : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq ? { ...item, p_category: category } : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateBulk = (seq, stickers, bulk) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, stickers: stickers, p_bulk: bulk } : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq
            ? { ...item, stickers: stickers, p_bulk: bulk }
            : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateKeyword = (seq, keyword) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, p_tags: keyword } : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq ? { ...item, p_tags: keyword } : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateTagLang = (seq, lang) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, tag_lang: lang } : item
      )
    )
    // handleShow(
    //   packages
    //     .map(item => (item.p_id === seq ? { ...item, tag_lang: lang } : item))
    //     .filter(item => item.p_id === seq)[0]
    // )
  }

  const updateStickers = (seq, id, tags) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq
          ? {
              ...item,
              stickers: item.stickers.map((s) => {
                if (s.s_id === id) {
                  s = { ...s, tags: tags }
                }
                return s
              }),
            }
          : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq
            ? {
                ...item,
                stickers: item.stickers.map((s) => {
                  if (s.s_id === id) {
                    s = { ...s, tags: tags }
                  }
                  return s
                }),
              }
            : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateStickers2 = (seq, sid) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq
          ? { ...item, stickers: item.stickers.filter((s) => s.s_id !== sid) }
          : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq
            ? { ...item, stickers: item.stickers.filter((s) => s.s_id !== sid) }
            : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateStickerImage = (seq, sid, img) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq
          ? {
              ...item,
              stickers: item.stickers.map((s) => {
                if (s.s_id === sid) {
                  s = { ...s, s_img: img }
                }
                return s
              }),
            }
          : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq
            ? {
                ...item,
                stickers: item.stickers.map((s) => {
                  if (s.s_id === sid) {
                    s = { ...s, s_img: img }
                  }
                  return s
                }),
              }
            : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateStickerWebp = (seq, webp) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq
          ? {
              ...item,
              stickers: webp,
            }
          : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq
            ? {
                ...item,
                stickers: webp,
              }
            : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updatePackageMain = (seq, img) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, p_img: img } : item
      )
    )
    handleShow(
      packages
        .map((item) => (item.p_id === seq ? { ...item, p_img: img } : item))
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updatePackageTray = (seq, img) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, tray: img } : item
      )
    )
    handleShow(
      packages
        .map((item) => (item.p_id === seq ? { ...item, tray: img } : item))
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateIsAd = (seq, ad) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, p_isAd: ad } : item
      )
    )
    handleShow(
      packages
        .map((item) => (item.p_id === seq ? { ...item, p_isAd: ad } : item))
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateIsShow = (seq, show) => {
    setPackages(
      packages.map((item) =>
        item.p_id === seq ? { ...item, p_isShow: show } : item
      )
    )
    handleShow(
      packages
        .map((item) => (item.p_id === seq ? { ...item, p_isShow: show } : item))
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const updateIsFree = (seq, free) => {
    console.log(free)
    setPackages(
      packages.map((item) =>
        item.p_id === seq
          ? { ...item, p_free: free, artiSubPackageYn: 'N' }
          : item
      )
    )
    handleShow(
      packages
        .map((item) =>
          item.p_id === seq
            ? { ...item, p_free: free, artiSubPackageYn: 'N' }
            : item
        )
        .filter((item) => item.p_id === seq)[0]
    )
  }

  const insertStickerTag = () => {
    insertStickerTagRef.current.insertStickerTag()
  }

  const updatePackageStatus = async (p_id, commit, emailYn, searchYn, telegramYn) => {

    const params = {
      p_id: p_id,
      commit: commit,
      emailYn: emailYn ? emailYn : 'N',
      searchYn: searchYn ? searchYn : 'N',
      telegramYn: telegramYn ? telegramYn : 'N',
    }

    const response = await fetch('/new/webAdmin/v2/commit', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'PUT',
      body: JSON.stringify(params),
    })

    const responseJson = await response.json()
    if (responseJson.status === 'fail' && responseJson.code === '1111')
      props.history.push('/')

    if (responseJson.status === 'success') {
      if (commit === 1) {
        // if (responseJson.webp) {
        //   fetch('/webApp/webp/' + p_id, {
        //     headers: {
        //       Accept: 'application/json',
        //       'Content-Type': 'application/json',
        //       'Access-Control-Allow-Origin': '*',
        //     },
        //     method: 'GET',
        //   })
        // }
        // insertStickerTag()
      }

      setPackages(
        packages.map((item) =>
          item.p_id === p_id ? { ...item, commit: commit } : item
        )
      )

      alert('완료')
      setShow(false)
    } else {
      alert('실패')
    }
  }

  const deletePackage = (p_id) => {
    const result = window.confirm('정말로 삭제하시겠습니까?')
    if (result) {
      const params = {
        p_id: p_id,
      }

      fetch('/new/webAdmin/v2/contents', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem('token'),
        },
        method: 'DELETE',
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === 'fail' && responseJson.code === '1111')
            props.history.push('/')
          if (responseJson.status === 'success') {
            setPackages(packages.filter((item) => item.p_id !== p_id))
            setShow(false)
          } else {
            alert('실패')
          }
        })
        .catch((error) => {
          console.error('123=' + error)
          alert('실패')
        })
    }
  }

  const saveTelegramLink = (p_id, telegramLink) => {
    if (!telegramLink || telegramLink.length < 1) {
      alert('스티커팩 이름은 1글자 이상이어야 합니다')
      return
    }
    const params = {
      p_id: p_id,
      telegramLink: telegramLink,
    }

    fetch('/new/webAdmin/v2/package/telegram', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
      },
      method: 'post',
      body: JSON.stringify(params),
    })
      .then(() => {
        setPackages(
          packages.map((item) =>
            item.p_id === p_id
              ? { ...item, p_telegramLink: telegramLink }
              : item
          )
        )
        alert('완료')
      })
      .catch((error) => {
        console.error('123=' + error)
        alert('실패')
      })
  }

  const orderDown = async () => {
    setOrder('downCnt')
  }

  // const nextPackage = async pid => {
  //   const nextPid =
  //     packages.indexOf(packages.find(item => item.p_id === pid)) + 1
  //   if (nextPid > 24) {
  //     await setParams({ ...params, pageNumber: Number(params.pageNumber) + 1 })
  //     // await props.history.push(
  //     //   `/${pageName}?search=${params.search}&type=${params.type}&order=${
  //     //     params.order
  //     //   }&language=${params.language}&pageNumber=${
  //     //     Number(params.pageNumber) + 1
  //     //   }`
  //     // )
  //     await handleShow(packages[0])
  //   } else {
  //     await handleShow(packages[nextPid])
  //   }
  // }

  const [currentIdx, setCurrentIdx] = useState(-1)
  const [nextIdx, setNextIdx] = useState(0)

  const nextPackage = async () => {
    await setNextIdx(currentIdx + 1)
    if (currentIdx < 24) {
      await handleShow(packages[currentIdx + 1])
    } else {
      await setParams({ ...params, pageNumber: Number(params.pageNumber) + 1 })
      await props.history.push(
        `/${pageName}?search=${encodeURIComponent(params.search)}&type=${
          params.type
        }&order=${params.order}&language=${params.language}&pageNumber=${
          Number(params.pageNumber) + 1
        }`
      )
    }
  }

  const previousPackage = async () => {
    await setNextIdx(currentIdx - 1)
    if (currentIdx > 0) {
      await handleShow(packages[currentIdx - 1])
    } else {
      if (params.pageNumber > 1) {
        await setParams({
          ...params,
          pageNumber: Number(params.pageNumber) - 1,
        })
        await props.history.push(
          `/${pageName}?search=${encodeURIComponent(params.search)}&type=${
            params.type
          }&order=${params.order}&language=${params.language}&pageNumber=${
            Number(params.pageNumber) - 1
          }`
        )
      }
    }
  }

  useEffect(() => {
    setCurrentIdx(
      modalPackage
        ? packages.indexOf(
            packages.find((item) => item.p_id === modalPackage.p_id)
          )
        : -1
    )
  }, [modalPackage])

  if (isLoading) {
    return <Loading />
  } else {
    return (
      <div
        //  className="content-wrapper"
        style={{ minHeight: '100%' }}
      >
        <section className="content-header" style={{ marginBottom: '15px' }}>
          <h1>컨텐츠 관리</h1>
          <ol className="breadcrumb">
            <li>
              <a href="/ContentsList">
                <i className="fa fa-dashboard"></i> Contents
              </a>
            </li>
            <li className="active">컨텐츠 관리</li>
          </ol>
        </section>
        <Container fluid>
          <ContentsListTopBar params={params} setParams={setParams} />
          <ContentsListTable
            contents={packages}
            languages={languages}
            language={params.language}
            handleShow={handleShow}
            handleClick={handleClick}
            orderDown={orderDown}
            params={params}
            setParams={setParams}
          />
          <ContentsListBottomBar
            selectedPackages={selectedPackages}
            pageNumber={params.pageNumber}
            pageMap={pageMap}
            pageName={pageName}
            search={params.search}
            type={params.type}
            order={params.order}
            language={params.language}
          />
        </Container>
        {show && (
          <ModalWrapper>
            <ContentsInfoModal
              show={show}
              setShow={setShow}
              package={modalPackage}
              languages={languages}
              guidelines={guidelines}
              categories={categories}
              updatePackageName={updatePackageName}
              updateLang={updateLang}
              updateCharacterName={updateCharacterName}
              updateDescription={updateDescription}
              updateCategory={updateCategory}
              updateBulk={updateBulk}
              updateKeyword={updateKeyword}
              updateTagLang={updateTagLang}
              updateStickers={updateStickers}
              updateStickers2={updateStickers2}
              updateStickerImage={updateStickerImage}
              updateStickerWebp={updateStickerWebp}
              updatePackageTitles={updatePackageTitles}
              updatePackageBookmark={updatePackageBookmark}
              updatePackagePremium={updatePackagePremium}
              updateIsUS={updateIsUS}
              updateIsAd={updateIsAd}
              updatePackageMain={updatePackageMain}
              updatePackageTray={updatePackageTray}
              updateIsShow={updateIsShow}
              updateIsFree={updateIsFree}
              updatePackageStatus={updatePackageStatus}
              ref={insertStickerTagRef}
              deletePackage={deletePackage}
              saveTelegramLink={saveTelegramLink}
              libraries={libraries}
              selfServingGroups={selfServingGroups}
              appCategory={appCategory}
              region={region}
              selectedRegion={selectedRegion}
              selectedLang={selectedLang}
              guidelinesOfThePackage={guidelinesOfThePackage}
              getGuidelinesOfThePackage={getGuidelinesOfThePackage}
              librariesOfThePackage={librariesOfThePackage}
              selectedCategory={selectedCategory}
              getLibrariesAndSelfServingGroupOfThePackage={
                getLibrariesAndSelfServingGroupOfThePackage
              }
              nextPackage={nextPackage}
              currentIdx={currentIdx}
              previousPackage={previousPackage}
            />
          </ModalWrapper>
        )}
      </div>
    )
  }
}

export default withRouter(ContentsList)

const ModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
`
