import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { paging } from "../../Common/Page";

const Content = styled.section`
    min-height: 250px;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
`;

const Box = styled.section`
    min-height: 100px;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    display: block;
`;

const BoxInfo = styled.div`
    border-top-color: #00c0ef;
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    display: block;
`;

const BoxHeader = styled.div`
    position: relative;
    display: table;
    border-collapse: separate;
    width: ${props => props.width ? props.width:'100%'};
    margin: 10px;
`;
// color: #444;
// display: block;
// padding: 10px;
// position: relative;
// float: left;
// width: 100%;

const BoxBody = styled.div`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 10px;
    min-height: .01%;
    overflow-x: auto;
`;



const Body = ({HeaderComponent, DataComponet, headerWidth, pageMap, search}) => {
    return (
        <Content>
            <Row>
                <Box>
                    <BoxInfo>
                        <BoxHeader width={headerWidth}>
                            {HeaderComponent && HeaderComponent}
                            {/* <HeaderComponent /> */}
                        </BoxHeader>
                        <BoxBody>
                            { DataComponet && <DataComponet />}
                        </BoxBody>
                    </BoxInfo>
                    {pageMap && paging(pageMap.pageNumber, pageMap, pageMap.pageName, search)}
                </Box>
            </Row>
        </Content>
    )
}

export default Body;