import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import EditCurationPackage from './EditCurationPackage'
import axios from 'axios'
import { SpaceResponsive } from '../../Pages/NewApp/NewAppBannerPage'

const EditTable = props => {
  const {
    tableData,
    getCollection,
    getStickers,
    page,
    version,
    lang,
    edit,
    setEdit,
    platform,
    filter
  } = props

  const [data, setData] = useState([])
  const [checked, setChecked] = useState([])
  const [drag, setDrag] = useState(false)

  const checkRef = useRef([])
  const curationRef = useRef()
  const [isScroll, setIsScroll] = useState('')
  const [posY, setPosY] = useState(0)

  useEffect(() => {
    setData(tableData)
  }, [tableData])

  useEffect(() => {
    setEdit('')
    if (checkRef.current) {
      for (let i = 0; i < checkRef.current.length; i++) {
        if (checkRef.current[i] && checkRef.current[i].checked) {
          checkRef.current[i].checked = false
        }
      }
    }
    setChecked([])
  }, [page, lang, version])

  // useEffect(() => {
  //   console.log(data)
  // }, [data])

  // useEffect(() => {
  //   console.log(checked)
  // }, [checked])

  const handleCheck = e => {
    if (e.target.checked) {
      setChecked(checked.concat(Number(e.target.value)))
    } else {
      setChecked(checked.filter(id => id !== Number(e.target.value)))
    }
  }

  // drag and drop reorder
  const dragDataInit = {
    target: null,
    index: -1,
    move_up: [],
    move_down: [],
    updateList: [],
  }

  const [dragData, setDragData] = useState(dragDataInit)

  const _dragStart = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    setDragData({
      ...dragData,
      target: e.currentTarget,
      index: Number(e.currentTarget.dataset.index),
      updateList: [...data],
    })

    e.dataTransfer.setData('text/html', '')
    e.dataTransfer.effectAllowed = 'move'
  }

  const _dragEnter = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    const _dragged = Number(dragData.target.dataset.index)
    const _target = Number(e.currentTarget.dataset.index)
    let move_down = [...dragData.move_down]
    let move_up = [...dragData.move_up]

    if (_dragged > _target) {
      if (move_down.includes(_target)) {
        move_down = move_down.slice(0, move_down.indexOf(_target))
      } else {
        move_down = Array(_dragged - _target)
          .fill()
          .map((v, i) => i + _target)
          .reverse()
      }
      move_up = []
    } else if (_dragged < _target) {
      if (move_up.includes(_target)) {
        move_up = move_up.slice(0, move_up.indexOf(_target))
      } else {
        move_up = Array(_target - _dragged)
          .fill()
          .map((v, i) => _target - i)
          .reverse()
      }
      move_down = []
    } else {
      move_down = []
      move_up = []
    }

    setDragData({
      ...dragData,
      // updateList: updateData,
      index: _target,
      move_up,
      move_down,
    })
  }

  const _dragLeave = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    if (e.currentTarget === dragData.target) {
      e.currentTarget.style.visibility = 'hidden'
    }
  }

  const _dragEnd = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    let updateData = [...dragData.updateList]

    const changeOrder = (i, t) => {
      const target = updateData.splice(i, 1)[0]
      updateData.splice(t, 0, target)
    }

    const _dragged = Number(e.currentTarget.dataset.index)
    let _target = Number(dragData.index)

    if (dragData.move_up.length > 0) {
      _target = dragData.move_up[dragData.move_up.length - 1]
    } else if (dragData.move_down.length > 0) {
      _target = dragData.move_down[dragData.move_down.length - 1]
    } else {
      _target = _dragged
    }

    changeOrder(_dragged, _target)

    setDrag(false)
    setData(updateData)
    reorder(updateData)
    setDragData({
      ...dragData,
      move_down: [],
      move_up: [],
      updateList: [],
    })

    e.currentTarget.style.visibility = 'visible'
    e.dataTransfer.dropEffect = 'move'
  }

  const _dragCapture = async e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    const posH = e.target.getBoundingClientRect().height
    const bottom = curationRef.current.getBoundingClientRect().bottom
    const top = curationRef.current.getBoundingClientRect().top

    setPosY(posH)

    if (e.clientY && e.clientY > top && e.clientY < bottom) {
      if (bottom - e.clientY < 100) {
        setIsScroll('down')
      } else if (e.clientY - top < 100) {
        setIsScroll('up')
      } else {
        setIsScroll('')
      }
    }
  }

  useEffect(() => {
    const scrollHeight = curationRef.current.scrollTop
    const posH = curationRef.current.getBoundingClientRect().height

    if (isScroll === 'down') {
      curationRef.current.scrollTo({
        top: scrollHeight + posH - posY * 2,
        behavior: 'smooth',
      })
    }

    if (isScroll === 'up') {
      curationRef.current.scrollTo({
        top: scrollHeight - posH + posY * 2,
        behavior: 'smooth',
      })
    }
  }, [isScroll])

  //reorder
  const reorderPromise = (id, order) => {
    const params = {
      collectionId: id,
      order: order,
    }
    return new Promise(async (resolve, reject) => {
      axios
        .put(`/new/webAdmin/v2/collection${filter !== 'all' ? '/filter' : ''}/order`, params, {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  const reorder = async list => {
    const result = await Promise.all(
      list.map((item, index) => reorderPromise(item.collectionId, index + 1))
    )

    if (result) {
      getCollection(page, lang, version)
      getStickers(page, lang, version)
    }
  }

  //new banner
  const clickNew = () => {
    setEdit('new')
  }

  //delete banner
  const clickDelete = () => {
    if (checked.length > 0) {
      const params = {
        idList: checked,
      }
      axios
        .delete(`/new/webAdmin/v2/collection`, {
          headers: {
            token: sessionStorage.getItem('token'),
          },
          data: params,
        })
        .then(() => {
          setData(
            data.filter(item => !checked.includes(Number(item.collectionId)))
          )
          if (checkRef.current) {
            for (let i = 0; i < checkRef.current.length; i++) {
              if (checkRef.current[i] && checkRef.current[i].checked) {
                checkRef.current[i].checked = false
              }
            }
          }
          setChecked([])
          getCollection(page, lang, version)
          getStickers(page, lang, version)
        })
    }
  }

  return !edit ? (
    <Wrapper>
      {version.status === 'Y' ? (
        <SpaceResponsive height={30} style={{ margin: '15px' }} />
      ) : (
        <ButtonWrapper>
          <button
            style={{ backgroundColor: '#dc143c' }}
            onClick={() => clickDelete()}
          >
            선택 삭제
          </button>
          <button
            style={{ backgroundColor: '#007ffb' }}
            onClick={() => clickNew()}
          >
            새 배너 추가
          </button>
        </ButtonWrapper>
      )}
      <CollectionWrapper ref={curationRef}>
        {data &&
          data.map((item, index) => {
            let default_class = ''
            dragData.move_down.includes(index) && (default_class = 'move_down')
            dragData.move_up.includes(index) && (default_class = 'move_up')
            return (
              <CollectionItem
                key={index}
                data={item}
                data-index={index}
                draggable={drag}
                onDragStart={_dragStart}
                onDragEnter={_dragEnter}
                onDragLeave={_dragLeave}
                onDragEnd={_dragEnd}
                onDragCapture={_dragCapture}
                className={default_class}
                status={item.status}
              >
                <div style={{ width: '5%' }}>
                  <input
                    ref={el => (checkRef.current[index] = el)}
                    type="checkbox"
                    value={item.collectionId}
                    onChange={handleCheck}
                  />
                </div>
                <div style={{ width: '10%' }}>#{index + 1}</div>
                <div
                  style={{
                    width: '15%',
                    minWidth: '80px',
                    marginRight: '5px',
                    justifyContent: 'flex-start',
                    fontWeight: 'bold',
                  }}
                >
                  {item.collectionName}
                </div>
                <div
                  style={{
                    width: '60%',
                    justifyContent: 'flex-start',
                    fontWeight: 'bold',
                  }}
                >
                  {item.title !== 'null' ? item.title : ''}
                </div>
                <div style={{ width: '15%', justifyContent: 'flex-end' }}>
                  {version.status !== 'Y' && (
                    <button
                      id="edit-button"
                      onClick={e => {
                        setEdit(item.collectionId)
                      }}
                      style={{ marginRight: '15px' }}
                    >
                      Edit
                    </button>
                  )}
                  <img
                    id="drag"
                    src="https://img.stipop.io/image/account/hamburger_menu.png"
                    alt=""
                    draggable={false}
                    onMouseDown={() => setDrag(true)}
                  />
                </div>
              </CollectionItem>
            )
          })}
      </CollectionWrapper>
    </Wrapper>
  ) : (
    <Wrapper>
      <EditCurationPackage
        collectionData={data}
        collectionId={edit}
        setEdit={setEdit}
        getCollection={getCollection}
        getStickers={getStickers}
        page={page}
        version={version}
        lang={lang}
        platform={platform}
      />
    </Wrapper>
  )
}

export default EditTable

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  margin-left: 10px;
  padding-right: 10px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;

  button {
    height: 35px;
    padding: 0 10px;
    box-sizing: border-box;
    margin-left: 10px;
    color: #fff;
    border-radius: 5px;
    border: none;

    &:hover {
      opacity: 0.8;
      font-weight: bold;
    }
  }
`

const CollectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

const CollectionItem = styled.div`
  width: 100%;
  min-width: 700px;
  height: 90px;
  background-color: #fff;
  border-radius: 10px;
  border: ${props =>
    props.status === 'Y' ? '2px solid #007bff' : '1px solid #000'};
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 15px;
  box-sizing: border-box;
  position: relative;
  opacity: 0.999;

  div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    #edit-button {
      height: 30px;
      border: none;
      background-color: #007ffb;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;
      border-radius: 5px;

      &:hover {
        opacity: 0.8;
      }
    }

    #drag {
      width: 25px;
      height: 20px;
      cursor: pointer;
      z-index: 2;
    }

    button {
      margin-right: 10px;
    }
  }

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }

  &.move_up {
    transform: translateY(-90px);
    z-index: 1;
  }
  &.move_down {
    transform: translateY(90px);
    z-index: 1;
  }
`
