import React, {forwardRef} from 'react'
import styled from 'styled-components'

const AiCard = forwardRef((props, {checkRef}) => {
  const { item, index, handleCheck, data, setData, setDrag, assistantPreview } = props

  return (
<>
<CheckBox>
          <input
            ref={el => (checkRef.current[index] = el)}
            type="checkbox"
            value={item.seq}
            onChange={handleCheck}
          />
        </CheckBox>
        <Index>#{index + 1}</Index>
        <TextInput>
          <span>Assistant ID</span>
          <input
            type="text"
            value={item.assistantId ? item.assistantId : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq ? { ...d, assistantId: e.target.value } : d
                )
              )
            }}
            onBlur={e => {
              if (e.target.value) {
                assistantPreview(item)
              }
            }}
          />
        </TextInput>
        <Preview
        >
          <img
            id="preview"
            src={item.assistantProfileImg ? item.assistantProfileImg : ''}
            alt=""
          />
        </Preview>
        <Order>
          <img
            id="drag"
            src="https://img.stipop.io/image/account/hamburger_menu.png"
            alt=""
            draggable={false}
            onMouseDown={() => setDrag(true)}
          />
        </Order>
</>
  )
})

export default AiCard

const CheckBox = styled.div`
  width: 5%;
  min-width: 33.5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }
`

const Index = styled.div`
  width: 5%;
  min-width: 33.5px;
  height: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`

const TextInput = styled.div`
  width: 50%;
  min-width: 120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  span {
    width: 90%;
    font-size: 12px;
    font-weight: bold;
  }

  input[type='text'] {
    width: 90%;
    height: 30px;
  }
`

const Preview = styled.div`
  width: 35%;
  min-width: 120px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    max-width: 100%;
    background-color: ${props => props.bgColor};
  }
`

const Order = styled.div`
  width: 10%;
  min-width: 67px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`