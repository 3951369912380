import React from "react";
import Assistant from "../../Components/Assistant/Assistant";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const AssistantPage = ({ match, location }) => {
    
    const query = queryString.parse(location.search);
    
    return (
        <React.Fragment>
        {/* <LeftMenu /> */}
        <Assistant
            pageNumber={query.pageNumber}
            search={query.search ? query.search : ""}
        />
        </React.Fragment>
    );
};

export default AssistantPage;
