import React, { Fragment } from 'react'
import styled from 'styled-components'
import { SpaceResponsive, Title } from '../../Pages/NewApp/NewAppBannerPage'

const AiCard = props => {
  const { title, assistantList, lang } = props

  return (
    <>
      <Title>{title}</Title>
      <SpaceResponsive height={16} />
      <Wrapper>
        <StickerWrapper>
          {assistantList &&
            assistantList.map((assistant, index) => (
              <StickerItem key={index} onClick={() => {}}>
                <img
                  src={
                    assistant.profileImg ||
                    'https://img.stipop.io/image/stipop/icon/chat/chat-create-profile-icon.png'
                  }
                  alt=""
                />
                <AssistantTitle>
                  {assistant.assistantInfo
                    ? assistant.assistantInfo.filter(a => a.lang === lang)[0]
                      ? assistant.assistantInfo.filter(a => a.lang === lang)[0]
                          .assistantName
                      : assistant.assistantName
                    : assistant.assistantName}
                </AssistantTitle>
                <AssistantIntro>
                  {assistant.assistantInfo
                    ? assistant.assistantInfo.filter(a => a.lang === lang)[0]
                      ? assistant.assistantInfo.filter(a => a.lang === lang)[0]
                          .intro
                      : assistant.intro
                    : assistant.intro}
                </AssistantIntro>
              </StickerItem>
            ))}
        </StickerWrapper>
      </Wrapper>
      <SpaceResponsive height={34} />
    </>
  )
}

export default AiCard

const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  position: relative;

  /* scrollbar-color: transparent transparent;
  scrollbar-width: 0;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  } */
`

const StickerWrapper = styled.div`
  display: flex;
`

const StickerItem = styled.div`
  min-width: 120px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  word-wrap: break-word;
  word-break: keep-all;

  img {
    width: 120px;
    height: 120px;
    border-radius: 12px;
    object-fit: cover;
  }

  &:hover {
    cursor: pointer;
  }
`

const AssistantTitle = styled.div`
  width: 100%;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AssistantIntro = styled.div`
  width: 120px;
  font-size: 10px;
  font-weight: 400;
  font-family: Poppins;
  color: #787878;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
