import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { withRouter } from 'react-router-dom'

import Loading from '../Common/Loading/Loading'
import Header from '../Common/Header'
import Body from '../Common/Body'
import List from '../Common/List'
import Pagination from 'react-bootstrap/Pagination'
import { InputGroup, FormControl, Button } from 'react-bootstrap'

const assistant = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [models, setModels] = useState([])
  const [assistantList, setAssistantList] = useState([])
  const [filedList, setFiledList] = useState([])
  const [pageMap, setPageMap] = useState({})
  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState('')

  const getAssistantList = async (pageNumber, search) => {
    const response = await fetch(
        `https://api.stipop.com/v4.0.1/assistant?pageNumber=${pageNumber}&limit=100&search=${search}`,
    //   `http://localhost:3000/v4/assistant?pageNumber=${pageNumber}&limit=100&search=${search}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem('token'),
            apikey: 'a590539c92b911d3ed83f1be7db9f7d0',
        //   apikey: 'a3e68b6c54d8132f6879d5bc2c49708d',
          isdev: 'Y',
        },
        method: 'get',
      }
    )

    const responseJson = await response.json()

    if (filedList.length === 0) {
      let fileds = []

      for (let key in responseJson.body[0]) {
        fileds.push(key)
      }

      setFiledList(fileds)
    }


    setAssistantList(responseJson.body.assistantList)
    setPageMap(responseJson.body.pageMap)
  }

  const getModelList = async () => {
    const response = await fetch(`https://api.stipop.com/v4.0.1/assistant/models`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
        apikey: 'a590539c92b911d3ed83f1be7db9f7d0',
        isdev: 'Y',
      },
      method: 'get',
    })

    const responseJson = await response.json()

    setModels(responseJson.body)
  }

  const assistantDelete = async assistantId => {
    fetch(`https://api.stipop.com/v4.0.1/assistant/${assistantId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
        apikey: 'a590539c92b911d3ed83f1be7db9f7d0',
        isdev: 'Y',
      },
      method: 'delete',
    })

    alert('삭제')

    window.location.reload()
  }

  const assistantUpdate = async data => {
    console.log(data)

    data && props.history.push('/AssiCreateDev', { data, models })
  }

  const statusUpdate = async (assistantId, status) => {
    const params = {
      status,
    }

    const response = await fetch(
      `https://api.stipop.com/v4.0.1/assistant/${assistantId}/status`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          token: sessionStorage.getItem('token'),
          apikey: 'a590539c92b911d3ed83f1be7db9f7d0',
          isdev: 'Y',
        },
        method: 'put',
        body: JSON.stringify(params),
      }
    )

    alert('success')
  }

  const updateLive = async data => {
    fetch(`https://api.stipop.com/v4.0.1/assistant/${data.assistantId}/live`, {
    // fetch(`http://localhost:3000/v4/assistant/${data.assistantId}/live`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token: sessionStorage.getItem('token'),
        apikey: 'a590539c92b911d3ed83f1be7db9f7d0',
        // apikey: 'a3e68b6c54d8132f6879d5bc2c49708d',
      },
      method: 'post',
      body: JSON.stringify(data),
    })

    alert('success')
  }

  const handleSubmit = event => {
    event.preventDefault()
    setPageNumber(1)
    getAssistantList(1, search)
  }

  const keyEvent = event => {
    if (event.key === 'Enter') {
      handleSubmit(event)
    }
  }

  const renderPaging = (pageNumber, pageMap) => {
    let list = []
    let j = 0
    let i = 0

    let test = pageNumber !== 1 ? pageNumber - 1 : 1
    let last =
      pageNumber !== pageMap.pageCount ? pageNumber * 1 + 1 : pageMap.pageCount

    list.push(
      <Pagination.Item
        key="first"
        onClick={() => {
          setPageNumber(test)
        }}
      >
        &laquo;
      </Pagination.Item>
    )

    for (i = pageMap.startPage * 1; i <= pageMap.endPage; i++) {
      let num = pageMap.startPage * 1 + j

      if (num === pageMap.pageNumber * 1) {
        list.push(
          <Pagination.Item key={i} className="active">
            {num}
          </Pagination.Item>
        )
      } else {
        list.push(
          <Pagination.Item
            key={i}
            onClick={() => {
              setPageNumber(num)
            }}
          >
            {num}
          </Pagination.Item>
        )
      }
      j++
    }

    list.push(
      <Pagination.Item
        key="last"
        onClick={() => {
          setPageNumber(last)
        }}
      >
        &raquo;
      </Pagination.Item>
    )
    return <Pagination>{list}</Pagination>
  }

  const dataComponent = () => {
    return (
      <List
        fieldList={filedList}
        dataList={assistantList}
        isAssistant={true}
        isAssistantDev={true}
        assistantDelete={assistantId => assistantDelete(assistantId)}
        assistantUpdate={data => assistantUpdate(data)}
        statusUpdate={(assistantId, status) =>
          statusUpdate(assistantId, status)
        }
        assistantToLive={data => updateLive(data)}
      />
    )
  }

  useEffect(() => {
    const fetchData = async () => {
      await getAssistantList(pageNumber, search)
      await getModelList()
      setIsLoading(false)
    }

    fetchData()

    return async () => {}
  }, [pageNumber])

  if (isLoading) {
    return <Loading />
  } else {
    return (
      <>
        <Header
          title={'Assistant DEV'}
          button={true}
          onClick={() => props.history.push('/AssiCreateDev', { models })}
        />
        <InputGroup onSubmit={handleSubmit}>
            <FormControl
            type="text"
            value={search}
            placeholder="Search"
            onChange={event => setSearch(event.target.value)}
            onKeyPress={keyEvent}
            />
            <Button variant="primary" onClick={handleSubmit} style={{ height: '38px'}}>
            <i className="fa fa-search" />
            </Button>
        </InputGroup>
        <Body headerWidth={'20%'} DataComponet={dataComponent} />
        {renderPaging(pageNumber, pageMap)}
      </>
    )
  }
}

export default withRouter(assistant)
