import React from "react";
import AssistantCreate from "../../Components/Assistant/AssistantCreate";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const AssistantCreatePage = ({ match, location }) => {
  
    // const query = queryString.parse(location.search);
  
    return (
        
        <React.Fragment>
        {/* <LeftMenu /> */}
            <AssistantCreate />
        </React.Fragment>

    );
};

export default AssistantCreatePage;
