import React, { useState, useEffect } from 'react'
import axios from 'axios'
import LanguageJsonView from '../../Components/Language/LanguageJsonView'

const LanguagePage = () => {
  const [langJson, setLangJson] = useState([])
  const [lang, setLang] = useState('ko')

  const getLangJson = () => {
    axios
      .get(`/new/webAdmin/v2/lang/json?lang=${lang}`, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(({ data }) => {
        setLangJson(data.result)
      })
  }

  useEffect(() => {
    getLangJson()
  }, [lang])

  return <LanguageJsonView getLangJson={getLangJson} langJson={langJson} lang={lang} setLang={setLang} />
}

export default LanguagePage
