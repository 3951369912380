import React from 'react'
import ReactApexChart from 'react-apexcharts'

const EmergencyChart = props => {
  const { data } = props

  const state = {
    series: [
      {
        name: 'No request',
        data: data.map(d => d.userRequest - d.azureRequest),
      },
    ],
    options: {
      chart: {
        height: 300,
        type: 'area',
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      annotations: {
        yaxis: [
          {
            y: 800,
            borderColor: '#ff0000',
            label: {
              borderColor: '#ff0000',
              style: {
                color: '#fff',
                background: '#ff0000',
              },
              text: '비상사태 ON',
            },
          },
        ],
      },
      stroke: {
        curve: 'straight',
        width: 2,
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        // categories: data.filter((d, i) => i % 60 === 0).map(d => d.time.slice(0, 5)),
        // tickAmount: 10,
        categories: data.map(d => d.time.slice(0, 5)),
        tickAmount: 24,
      },
    },
  }

  return (
    <div style={{ width: '100%' }}>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="area"
        height={350}
      />
    </div>
  )
}

export default EmergencyChart
