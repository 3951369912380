import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import ReactJson from 'react-json-view'
import { DropdownButton, Dropdown } from 'react-bootstrap'

const LanguageJsonView = props => {
  const { getLangJson, langJson, lang, setLang } = props
  const [edit, setEdit] = useState(0)
  const [params, setParams] = useState({
    languageJson: {},
  })
  const [inputJson, setInputJson] = useState('')
  const [pType, setPType] = useState(true) //true: json, false: string
  const langList = ['ko', 'en', 'ja', 'pt', 'es']

  const init = async () => {
    await getLangJson()
    await setEdit(0)
    await setParams({ languageJson: {} })
    await setInputJson('')
    await setPType(true)
  }

  useEffect(() => {
    if (edit) {
      const editItem = langJson.filter(item => item.seq === edit)[0]
      setParams({
        ...params,
        languageJson: editItem.data,
      })
    }
  }, [edit])

  const validJSON = str => {
    try {
      const josn = JSON.parse(str)
      return typeof josn === 'object'
    } catch (err) {
      return false
    }
  }

  const clickSave = async str => {
    await axios.put(
      `/new/webAdmin/v2/lang/json/${edit}`,
      {
        ...params,
        languageJson: JSON.stringify(str ? str : params.languageJson),
      },
      {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      }
    )

    await init()
  }

  const renderEdit = () => {
    return (
      <Table className="table no-margin">
        <thead>
          <tr>
            <th style={{ minWidth: '800px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                }}
              >
                <div style={{ marginRight: '20px' }}>Parameters</div>
                <div
                  onClick={async () => {
                    await setInputJson(
                      JSON.stringify(params.languageJson, null, 4)
                    )
                    await setPType(false)
                  }}
                  style={{
                    fontSize: '14px',
                    color: pType ? '#0000004d' : '#268bd2',
                    cursor: 'pointer',
                    marginRight: '10px',
                  }}
                >
                  String
                </div>
                <div
                  onClick={async () => {
                    if (validJSON(inputJson)) {
                      await setParams({
                        ...params,
                        languageJson: JSON.parse(inputJson),
                      })
                      await setPType(true)
                    } else {
                      alert('This text is not in valid json format.')
                    }
                  }}
                  style={{
                    fontSize: '14px',
                    color: !pType ? '#0000004d' : '#268bd2',
                    cursor: 'pointer',
                  }}
                >
                  JSON
                </div>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ minWdith: '800px' }}>
              {pType ? (
                <ReactJson
                  src={params.languageJson}
                  theme={'summerfruit:inverted'}
                  collapsed={false}
                  onAdd={e => {
                    setParams({ ...params, languageJson: e.updated_src })
                  }}
                  onEdit={e => {
                    setParams({ ...params, languageJson: e.updated_src })
                  }}
                  onDelete={e => {
                    setParams({ ...params, languageJson: e.updated_src })
                  }}
                  enableClipboard={edit && false}
                  name={null}
                />
              ) : (
                <>
                  <textarea
                    value={inputJson}
                    onChange={e => {
                      setInputJson(e.target.value)
                    }}
                    style={{ minWidth: '800px', minHeight: '800px' }}
                  />
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#dc3545',
                    }}
                  >
                    {!validJSON(inputJson) &&
                      'This text is not in valid json format.'}
                  </div>
                </>
              )}
            </td>
            <td>
              <button
                className="btn btn-default"
                onClick={async () => {
                  if (pType) {
                    clickSave()
                  } else {
                    if (!validJSON(inputJson)) {
                      alert('This text is not in valid json format.')
                    } else {
                      clickSave(JSON.parse(inputJson))
                    }
                  }
                }}
              >
                저장
              </button>
              <button
                className="btn btn-default"
                onClick={() => {
                  init()
                }}
              >
                취소
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  return (
    <div style={{ minHeight: '100%' }}>
      <section className="content-header">
        <h1>Language JSON 관리</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/LanguageJson">
              <i className="fa fa-dashboard"></i>Language Json
            </a>
          </li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <section className="col-lg-12 connectedSortable">
            <div className="box box-info">
              <div
                className="box-header with-border"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <DropdownButton
                  variant="dark"
                  id="commit-status-dropdown"
                  title={lang}
                >
                  {langList.map((data, index) => {
                    return (
                      <Dropdown.Item key={index} onClick={() => setLang(data)}>
                        {data}
                      </Dropdown.Item>
                    )
                  })}
                </DropdownButton>
              </div>
              {langJson.length > 0 && (
                <div className="box-body">
                  <div className="table-responsive">
                    {edit ? (
                      renderEdit()
                    ) : (
                      <Table className="table no-margin">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Parameters</th>
                            <th>Reg Date</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {langJson &&
                            langJson.map((item, index) => (
                              <tr key={index}>
                                <td>{item.seq}</td>
                                <td style={{ minWidth: '500px' }}>
                                  <ReactJson
                                    src={item.data}
                                    collapsed={false}
                                    name={null}
                                    displayDataTypes={false}
                                    displayObjectSize={false}
                                    quotesOnKeys={false}
                                    displayArrayKey={false}
                                  />
                                </td>
                                <td>
                                  {new Date(item.regDate).toLocaleString()}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-default"
                                    onClick={() => {
                                      setEdit(item.seq)
                                    }}
                                  >
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}

export default LanguageJsonView

const Table = styled.table`
  td {
    /* max-width: 300px; */
    white-space: break-spaces;
  }
`
