import React from 'react'
import styled from 'styled-components'
import VideoUpload from './VideoUpload'
import _ from 'lodash'
import axios from 'axios'
import LoadingSpinner from '../Common/Loading/LoadingSpinner'

const ChatContentView = (props) => {
  const {
    type,
    setType,
    artistId,
    setArtistId,
    contentList,
    clickSearch,
    updateTag,
    updateShare,
    clickSave,
    clickDelete,
    uploadList,
    setUploadList,
    isLoading,
    setIsLoading,
    exportData,
    contentLoading,
  } = props

  const videoReadAsDataURL = (file, size, transformedSize) => {
    return new Promise((resolve, reject) => {
      const fileSize = file.size
      //파일사이즈 검증이 필요없는 경우, size는 fileSize보다 크게, 경고메세지로 표시되는 transformedSize 는 0으로
      size = size || fileSize + 1
      transformedSize = transformedSize || 0

      if (fileSize > size) {
        reject(`File must be ${transformedSize} or less`)
      }

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        resolve(reader.result)
      })

      reader.readAsDataURL(file)
    })
  }

  const handleFileChange = async (e) => {
    await e.persist()
    await Promise.all(
      _.map(e.target.files, async (file) =>
        videoReadAsDataURL(file, 204800000, '200MB')
      )
    ).then(async () => {
      _.map(e.target.files, (file) => {
        setUploadList((prev) => [
          ...prev,
          {
            video: file,
            name: file.name.split('.')[0].replaceAll('_', ' '),
            tag: '',
            share: '',
            mature: 'N',
            schedule: 'N',
            saleType: 'free',
          },
        ])
      })
    })
  }

  const uploadPromise = (formData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/new/webAdmin/v2/artist/video`, formData, {
          headers: { token: sessionStorage.getItem('token') },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const clickUpload = async () => {
    if (isLoading) return

    await setIsLoading(true)

    await Promise.all(
      await uploadList.map(async (video) => {
        const formData = new FormData()
        await formData.append('artistId', artistId || null)
        await formData.append('title', video.name || null)
        await formData.append('tags', video.tag || null)
        await formData.append('mature', video.mature || null)
        await formData.append('saleType', video.saleType || null)
        await formData.append('schedule', video.schedule || null)
        await formData.append('video', video.video || null)
        await formData.append('share', video.share || null)
        await uploadPromise(formData).then((res) => {
          console.log(res)
        })
      })
    ).then(async () => {
      await clickSearch()
      await setIsLoading(false)
    })
  }

  return (
    <div style={{ minHeight: '100%' }}>
      <section className="content-header">
        <h1>AI Chat Content 관리</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/ContentsList">
              <i className="fa fa-dashboard"></i>AI
            </a>
          </li>
          <li className="active">Chat Content</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <section className="col-lg-12 connectedSortable">
            <div className="box box-info">
              <div
                className="box-header with-border"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="text"
                    value={artistId}
                    onChange={(e) => setArtistId(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') clickSearch()
                    }}
                  />
                  <button
                    className="btn btn-default"
                    onClick={() => clickSearch()}
                  >
                    Search
                  </button>
                </div>
                {contentList && contentList.length > 0 && (
                  <button
                    className="btn btn-default"
                    onClick={() => exportData()}
                  >
                    Export
                  </button>
                )}
              </div>
              <div className="box-body">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <TypeButton
                      active={type === 'sticker'}
                      onClick={() => setType('sticker')}
                    >
                      sticker
                    </TypeButton>
                    <TypeButton
                      active={type === 'video'}
                      onClick={() => setType('video')}
                    >
                      video
                    </TypeButton>
                    <TypeButton
                      active={type === 'comic'}
                      onClick={() => setType('comic')}
                    >
                      comic
                    </TypeButton>
                  </div>
                  {type === 'video' &&
                    artistId &&
                    (uploadList.length > 0 ? (
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          clickUpload()
                        }}
                      >
                        Save
                      </button>
                    ) : (
                      <>
                        <label
                          className="btn btn-default"
                          htmlFor={'video-input'}
                        >
                          Upload
                        </label>
                        <input
                          id={'video-input'}
                          type="file"
                          multiple={true}
                          onChange={(e) => handleFileChange(e)}
                          style={{ display: 'none' }}
                        />
                      </>
                    ))}
                </div>
                <div className="table-responsive">
                  {type === 'video' && uploadList.length > 0 && (
                    <VideoUpload
                      uploadList={uploadList}
                      setUploadList={setUploadList}
                    />
                  )}
                  {!isLoading && (
                    <Table className="table no-margin">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th></th>
                          <th>Content Tags</th>
                          <th>When you can share the Content URL</th>
                          <th>Content ID</th>
                          <th>Content URL</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {contentList &&
                          contentList.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {type === 'sticker' ? (
                                  <img
                                    src={item.contentUrl}
                                    style={{ width: '80px', height: '80px' }}
                                    alt=""
                                  />
                                ) : (
                                  type === 'video' && (
                                    <video
                                      controls
                                      style={{
                                        width: '100px',
                                        height: 'auto',
                                      }}
                                    >
                                      <source src={item.contentUrl} />
                                    </video>
                                  )
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item.contentTag}
                                  onChange={(e) => {
                                    updateTag(item.contentId, e.target.value)
                                  }}
                                />
                              </td>
                              <td>
                                <textarea
                                  type="text"
                                  value={item.contentShare}
                                  onChange={(e) => {
                                    updateShare(item.contentId, e.target.value)
                                  }}
                                  style={{ width: '100%' }}
                                />
                              </td>
                              <td>{item.contentId}</td>
                              <td>{item.contentUrl}</td>
                              <td style={{ position: 'relative' }}>
                                {item.contentId === contentLoading ? (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: '50%',
                                      transform: 'translateY(-50%)',
                                    }}
                                  >
                                    <LoadingSpinner />
                                  </div>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        clickSave(item)
                                      }}
                                    >
                                      SAVE
                                    </button>
                                    {contentList.filter(
                                      (c) => c.contentId === item.contentId
                                    ).length > 1 && (
                                      <button
                                        className="btn btn-default"
                                        onClick={() => {
                                          clickDelete(item)
                                        }}
                                      >
                                        DELETE
                                      </button>
                                    )}
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}

export default ChatContentView

const Table = styled.table`
  td {
    white-space: break-spaces;
  }
`

const TypeButton = styled.div`
  padding: 0 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.active ? '#ff4500' : '#000')};
  cursor: pointer;
`
