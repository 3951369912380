import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { withRouter } from 'react-router-dom'

import Loading from '../../Common/Loading/Loading'
import Header from '../../Common/Header'
import styled from 'styled-components'
import axios from 'axios'
import {
  DropdownButton,
  Dropdown,
  Col,
  InputGroup,
  FormControl,
  Button,
} from 'react-bootstrap'
import Pagination from 'react-bootstrap/Pagination'

const assistant = props => {

    const [isLoading, setIsLoading] = useState(true)
    const [assistantList, setAssistantList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [fieldList, setFieldList] = useState([
        'profileImg',
        'assistantId',
        'artistId',
        'category',
        'createUser',
        'assistantName',
        'Name_en',
        'Name_ko',
        'Name_ja',
        'Name_es',
        'Name_pt',
        'instructions',
        'orderNum',
        'messageCnt',
        'assistantStatus',
        'regDate',
        'updateDate',
    ])
    const [status, setStatus] = useState('')
    const [params, setParams] = useState({
        assistantId: '',
        assistantName: '',
        intro: '',
        category: '',
        Name_ko: '',
        Name_en: '',
        Name_ja: '',
        Name_es: '',
        Name_pt: '',
        intro_ko: '',
        intro_en: '',
        intro_ja: '',
        intro_es: '',
        intro_pt: '',
        email: '',
    })

    const [pageMap, setPageMap] = useState({})
    const [pageNumber, setPageNumber] = useState(1)
    const [search, setSearch] = useState('')

    const emailList = [
        'ps7izuko@luravel.com',
        'wbkita@luravel.com',
        'lydia3601@luravel.com',
        'aolst@luravel.com',
        'totomkio@luravel.com',
        'sasaki7@luravel.com',
        'j.jun@luravel.com',
        'narunaru@luravel.com',
        'tonyk@luravel.com',
        'kyu451@luravel.com',
        'kzhiro@luravel.com',
        'shokujm@luravel.com',
        'gibaba@luravel.com',
        'sansour@luravel.com',
        'kxwxsto@luravel.com',
        'hiro62@luravel.com',
        'jjmh3@luravel.com',
        'danshoto@luravel.com',
        'kkhnn@luravel.com',
        'baktam0072@luravel.com',
    ]

    const getAssistantList = async (status, pageNumber, search) => {
        const response = await fetch(
        // `http://localhost:3000/v1/assistant?status=${status}&pageNumber=${pageNumber}&limit=30&search=${search}`,
        `https://api.royalchat.ai/v1/assistant?status=${status}&pageNumber=${pageNumber}&limit=30&search=${search}`,
        {
            headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            token: sessionStorage.getItem('token'),
            // apikey: '183a04c0815a6228c40e7dfa23a77ab3'
                apikey: 'b7d85e59d3a6bda31bc287b85ac2a83f'
            },
            method: 'get',
        }
        )

        const responseJson = await response.json()

        setAssistantList(responseJson.body.assistantList)
        setPageMap(responseJson.body.pageMap)
    }

    const getCategoryList = async () => {
        // const response = await fetch(`http://localhost:3000/v1/message/category`, {
        const response = await fetch(`https://api.royalchat.ai/v1/message/category`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            token: sessionStorage.getItem('token'),
            // apikey: '183a04c0815a6228c40e7dfa23a77ab3'
                apikey: 'b7d85e59d3a6bda31bc287b85ac2a83f'
        },
        method: 'get',
        })

        const responseJson = await response.json()

        setCategoryList(responseJson.body)
    }

    const updateAssistant = async (
        data,
        assistantName,
        category,
        intro,
        email
    ) => {

        const formData = new FormData()
        
        formData.append('profileImg', data.profileImg)
        formData.append('assistantName', assistantName || data.assistantName)
        formData.append('category', category || data.category)
        formData.append('instructions', data.instructions)
        formData.append('status', data.assistantStatus)
        formData.append('intro', intro || data.intro)

        formData.append('email', email || data.createUserEmail)

        await axios
        .put(
            // `http://localhost:3000/v1/assistant/${data.assistantId}`,
            `https://api.royalchat.ai/v1/assistant/${data.assistantId}`,
            formData,
            {
            headers: {
                token: sessionStorage.getItem('token'),
                // apikey: '183a04c0815a6228c40e7dfa23a77ab3',
                apikey: 'b7d85e59d3a6bda31bc287b85ac2a83f',
                'Access-Control-Allow-Origin': '*',
                // isDev: 'Y',
            },
            }
        )
        .then(async () => {
            setAssistantList(
                assistantList.map(item =>
                    item.assistantId === data.assistantId
                    ? {
                        ...item,
                        assistantName: assistantName || item.assistantName,
                        category: category || item.category,
                        intro: intro || item.intro,
                        createUserEmail: email || item.createUserEmail,
                        }
                    : item
                )
            )
            setParams({
                ...params,
                assistantName: assistantName || data.assistantName,
                category: category || data.category,
                intro: intro || data.intro,
                email: email || data.createUserEmail,
            })

            alert('success')
        })
    }

    const updateTranslation = async (data, assistantName, intro, lang) => {
        await axios
        .put(
            // `http://localhost:3000/v1/assistant/${data.assistantId}/translation`,
            `https://api.royalchat.ai/v1/assistant/${data.assistantId}/translation`,
            { assistantId: data.assistantId, assistantName, intro, lang },
            {
            headers: {
                token: sessionStorage.getItem('token'),
                // apikey: '183a04c0815a6228c40e7dfa23a77ab3',
                apikey: 'b7d85e59d3a6bda31bc287b85ac2a83f',
                'Access-Control-Allow-Origin': '*',
                // isdev: 'Y',
            },
            }
        )
        .then(async () => {
            setParams({
                ...params,
                [`Name_${lang}`]: assistantName,
                [`intro_${lang}`]: intro,
            })

            setAssistantList(
                assistantList.map(item =>
                    item.assistantId === data.assistantId
                    ? {
                        ...item,
                        assistantInfo: item.assistantInfo
                            ? item.assistantInfo.filter(item => item.lang === lang)
                                .length
                            ? item.assistantInfo.map(assistant =>
                                assistant.lang === lang
                                    ? { ...assistant, assistantName, intro }
                                    : assistant
                                )
                            : [...item.assistantInfo, { lang, assistantName, intro }]
                            : [{ lang, assistantName, intro }],
                        }
                    : item
                )
            )
            alert('success')
        })
    }

    const renderPaging = (pageNumber, pageMap) => {
        let list = []
        let j = 0
        let i = 0

        let test = pageNumber !== 1 ? pageNumber - 1 : 1
        let last =
        pageNumber !== pageMap.pageCount ? pageNumber * 1 + 1 : pageMap.pageCount

        list.push(
        <Pagination.Item
            key="first"
            onClick={() => {
            setPageNumber(test)
            }}
        >
            &laquo;
        </Pagination.Item>
        )

        for (i = pageMap.startPage * 1; i <= pageMap.endPage; i++) {
        let num = pageMap.startPage * 1 + j

        if (num === pageMap.pageNumber * 1) {
            list.push(
            <Pagination.Item key={i} className="active">
                {num}
            </Pagination.Item>
            )
        } else {
            list.push(
            <Pagination.Item
                key={i}
                onClick={() => {
                setPageNumber(num)
                }}
            >
                {num}
            </Pagination.Item>
            )
        }
        j++
        }

    list.push(
      <Pagination.Item
        key="last"
        onClick={() => {
          setPageNumber(last)
        }}
      >
        &raquo;
      </Pagination.Item>
    )
    return <Pagination>{list}</Pagination>
  }

  useEffect(() => {
    console.log(assistantList)
  }, [assistantList])

  const dataComponent = () => {
    return (
      <Table>
        <tbody>
          <tr>
            {fieldList.map((field, index) =>
              index === 5 ? (
                <DropdownButton
                  key={index}
                  title={fieldList[index]}
                  variant="dark"
                  id="commit-status-dropdown"
                  style={{ maxWidth: '400px' }}
                >
                  <Dropdown.Item
                    value="assistantName"
                    onClick={() => {
                      const list = [...fieldList]
                      list[5] = 'assistantName'
                      list[6] = 'Name_en'
                      list[7] = 'Name_ko'
                      list[8] = 'Name_ja'
                      list[9] = 'Name_es'
                      list[10] = 'Name_pt'

                      setFieldList(list)
                    }}
                  >
                    assistantName
                  </Dropdown.Item>
                  <Dropdown.Item
                    value="intro"
                    onClick={() => {
                      const list = [...fieldList]
                      list[5] = 'intro'
                      list[6] = 'intro_en'
                      list[7] = 'intro_ko'
                      list[8] = 'intro_ja'
                      list[9] = 'intro_es'
                      list[10] = 'intro_pt'

                      setFieldList(list)
                    }}
                  >
                    intro
                  </Dropdown.Item>
                </DropdownButton>
              ) : (
                <Field key={index}>{field}</Field>
              )
            )}
          </tr>
          {assistantList.map((data, index) => (
            <tr
              key={index}
              onClick={() =>
                setParams({
                  assistantId: data.assistantId,
                  assistantName: data.assistantName,
                  intro: data.intro,
                  category: data.category,
                  Name_ko: data.assistantInfo
                    ? data.assistantInfo.filter(item => item.lang === 'ko')[0]
                      ? data.assistantInfo.filter(item => item.lang === 'ko')[0]
                          .assistantName
                      : ''
                    : '',
                  Name_en: data.assistantInfo
                    ? data.assistantInfo.filter(item => item.lang === 'en')[0]
                      ? data.assistantInfo.filter(item => item.lang === 'en')[0]
                          .assistantName
                      : ''
                    : '',
                  Name_ja: data.assistantInfo
                    ? data.assistantInfo.filter(item => item.lang === 'ja')[0]
                      ? data.assistantInfo.filter(item => item.lang === 'ja')[0]
                          .assistantName
                      : ''
                    : '',
                  Name_es: data.assistantInfo
                    ? data.assistantInfo.filter(item => item.lang === 'es')[0]
                      ? data.assistantInfo.filter(item => item.lang === 'es')[0]
                          .assistantName
                      : ''
                    : '',
                  Name_pt: data.assistantInfo
                    ? data.assistantInfo.filter(item => item.lang === 'pt')[0]
                      ? data.assistantInfo.filter(item => item.lang === 'pt')[0]
                          .assistantName
                      : ''
                    : '',
                  intro_en: data.assistantInfo
                    ? data.assistantInfo.filter(item => item.lang === 'en')[0]
                      ? data.assistantInfo.filter(item => item.lang === 'en')[0]
                          .intro
                      : ''
                    : '',
                  intro_ko: data.assistantInfo
                    ? data.assistantInfo.filter(item => item.lang === 'ko')[0]
                      ? data.assistantInfo.filter(item => item.lang === 'ko')[0]
                          .intro
                      : ''
                    : '',
                  intro_ja: data.assistantInfo
                    ? data.assistantInfo.filter(item => item.lang === 'ja')[0]
                      ? data.assistantInfo.filter(item => item.lang === 'ja')[0]
                          .intro
                      : ''
                    : '',
                  intro_es: data.assistantInfo
                    ? data.assistantInfo.filter(item => item.lang === 'es')[0]
                      ? data.assistantInfo.filter(item => item.lang === 'es')[0]
                          .intro
                      : ''
                    : '',
                  intro_pt: data.assistantInfo
                    ? data.assistantInfo.filter(item => item.lang === 'pt')[0]
                      ? data.assistantInfo.filter(item => item.lang === 'pt')[0]
                          .intro
                      : ''
                    : '',
                  email: data.createUserEmail,
                })
              }
              style={{
                cursor: 'pointer',
                backgroundColor:
                  params.assistantId === data.assistantId && '#f9f9f9',
              }}
            >
              {fieldList.map((field, i) =>
                field === 'profileImg' || field === 'assistantImg' ? (
                  <td key={field + index}>
                    <img
                      src={data[field]}
                      style={{ width: '100px', height: '100px' }}
                      alt=""
                    />
                  </td>
                ) : field === 'instructions' ? (
                  <td
                    key={field + index}
                    style={{
                      maxWidth: '400px',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        maxHeight: '100px',
                        overflowY: 'auto',
                        wordWrap: 'break-word',
                        whiteSpace: 'break-spaces',
                      }}
                    >
                      {data[field]}
                    </div>
                  </td>
                ) : field === 'createUser' ? (
                  <td key={field + index}>
                    <DropdownButton
                      title={data.createUserEmail || ''}
                      variant="dark"
                    >
                      {emailList.map((email, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={async () => {
                            await updateAssistant(data, '', '', '', email)
                          }}
                        >
                          {email}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                    {params.assistantId === data.assistantId && (
                      <div style={{ display: 'flex' }}>
                        <input
                          type={'text'}
                          value={params.email}
                          onChange={e => {
                            setParams({ ...params, email: e.target.value })
                          }}
                        />
                        <button
                          onClick={() => {
                            updateAssistant(data, '', '', '', params.email)
                          }}
                        >
                          저장
                        </button>
                      </div>
                    )}
                  </td>
                ) : field === 'assistantName' ? (
                  <td
                    key={field + index}
                    style={{ maxWidth: '200px', minWidth: '200px' }}
                  >
                    {params.assistantId === data.assistantId ? (
                      <input
                        type={'text'}
                        value={params.assistantName}
                        onChange={e => {
                          setParams({
                            ...params,
                            assistantName: e.target.value,
                          })
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          maxHeight: '100px',
                          overflowY: 'auto',
                          wordWrap: 'break-word',
                          whiteSpace: 'break-spaces',
                        }}
                      >
                        {data.assistantName}
                      </div>
                    )}
                    {params.assistantId === data.assistantId && (
                      <>
                        <br />
                        <button
                          onClick={() => {
                            updateAssistant(data, params.assistantName, '', '')
                          }}
                        >
                          저장
                        </button>
                      </>
                    )}
                  </td>
                ) : [
                    'Name_en',
                    'Name_ko',
                    'Name_ja',
                    'Name_es',
                    'Name_pt',
                  ].indexOf(field) > -1 ? (
                  <td
                    key={field + index}
                    style={{ maxWidth: '200px', minWidth: '200px' }}
                  >
                    {params.assistantId === data.assistantId ? (
                      <input
                        type={'text'}
                        value={params[field]}
                        onChange={e => {
                          setParams({
                            ...params,
                            [field]: e.target.value,
                          })
                        }}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          maxHeight: '100px',
                          overflowY: 'auto',
                          wordWrap: 'break-word',
                          whiteSpace: 'break-spaces',
                        }}
                      >
                        {data.assistantInfo
                          ? data.assistantInfo.filter(
                              item => item.lang === field.split('_')[1]
                            )[0]
                            ? data.assistantInfo.filter(
                                item => item.lang === field.split('_')[1]
                              )[0].assistantName
                            : ''
                          : ''}
                      </div>
                    )}
                    {params.assistantId === data.assistantId && (
                      <>
                        <br />
                        <button
                          onClick={() => {
                            updateTranslation(
                              data,
                              params[field],
                              params[`intro_${field.split('_')[1]}`],
                              field.split('_')[1]
                            )
                          }}
                        >
                          저장
                        </button>
                      </>
                    )}
                  </td>
                ) : [
                    'intro_en',
                    'intro_ko',
                    'intro_ja',
                    'intro_es',
                    'intro_pt',
                  ].indexOf(field) > -1 ? (
                  <td
                    key={field + index}
                    style={{ maxWidth: '200px', minWidth: '200px' }}
                  >
                    {params.assistantId === data.assistantId ? (
                      <input
                        type={'text'}
                        value={params[field]}
                        onChange={e => {
                          setParams({
                            ...params,
                            [field]: e.target.value,
                          })
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          maxHeight: '100px',
                          overflowY: 'auto',
                          wordWrap: 'break-word',
                          whiteSpace: 'break-spaces',
                        }}
                      >
                        {data.assistantInfo
                          ? data.assistantInfo.filter(
                              item => item.lang === field.split('_')[1]
                            )[0]
                            ? data.assistantInfo.filter(
                                item => item.lang === field.split('_')[1]
                              )[0].intro
                            : ''
                          : ''}
                      </div>
                    )}
                    {params.assistantId === data.assistantId && (
                      <>
                        <br />
                        <button
                          onClick={() => {
                            updateTranslation(
                              data,
                              params[`Name_${field.split('_')[1]}`],
                              params[field],
                              field.split('_')[1]
                            )
                          }}
                        >
                          저장
                        </button>
                      </>
                    )}
                  </td>
                ) : field === 'intro' ? (
                  <td
                    key={field + index}
                    style={{ maxWidth: '200px', minWidth: '200px' }}
                  >
                    {params.assistantId === data.assistantId ? (
                      <input
                        type={'text'}
                        value={data.intro}
                        onChange={e => {
                          setAssistantList(
                            assistantList.map(item =>
                              item.assistantId === data.assistantId
                                ? { ...item, intro: e.target.value }
                                : item
                            )
                          )
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          maxHeight: '100px',
                          overflowY: 'auto',
                          wordWrap: 'break-word',
                          whiteSpace: 'break-spaces',
                        }}
                      >
                        {data.intro}
                      </div>
                    )}
                    {params.assistantId === data.assistantId && (
                      <>
                        <br />
                        <button
                          onClick={() => {
                            updateAssistant(data, '', '', data.intro)
                          }}
                        >
                          저장
                        </button>
                      </>
                    )}
                  </td>
                ) : field === 'category' ? (
                  <td key={field + index}>
                    <DropdownButton title={data.category || ''}>
                      {categoryList.map((category, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={async () => {
                            await updateAssistant(
                              data,
                              '',
                              category.category,
                              ''
                            )
                          }}
                        >
                          {category.category}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </td>
                ) : (
                  <td key={field + index}>{data[field]}</td>
                )
              )}
              {/* <td key={99999}>
                <button onClick={() => updateLive(data)}>Live 옮기기</button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  const handleSubmit = event => {
    event.preventDefault()
    setPageNumber(1)
    setStatus('')
    getAssistantList('', 1, search)
  }

  const keyEvent = event => {
    if (event.key === 'Enter') {
      handleSubmit(event)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await getAssistantList(status, pageNumber, search)
      await getCategoryList()
      setIsLoading(false)
    }

    fetchData()

    return async () => {}
  }, [status, pageNumber])

  useEffect(() => {
    setPageNumber(1)
  }, [status])

  if (isLoading) {
    return <Loading />
  } else {
    return (
      <>
        <Header
          title={'Assistant EDIT'}
          onClick={() => props.history.push('/AssiEdit')}
        />
        <Content>
          <Col
            style={{
              position: 'fixed',
              margin: '10px 0 0 5px',
              zIndex: 99,
            }}
          >
            <Row>
              <div
                style={{
                  display: 'flex',
                  columnGap: '10px',
                }}
              >
                <DropdownButton
                  variant="dark"
                  id="commit-status-dropdown"
                  title={
                    status === ''
                      ? 'ALL'
                      : status === 'U'
                      ? 'Unlisted'
                      : status === 'Y'
                      ? 'Public'
                      : status === 'P' && 'Private'
                  }
                >
                  <Dropdown.Item
                    onClick={() => {
                      setStatus('')
                    }}
                  >
                    ALL
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setStatus('Y')
                    }}
                  >
                    Public
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setStatus('U')
                    }}
                  >
                    Unlisted
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setStatus('P')
                    }}
                  >
                    Private
                  </Dropdown.Item>
                </DropdownButton>
                <DropdownButton
                  title={fieldList[5]}
                  variant="dark"
                  id="commit-status-dropdown"
                >
                  <Dropdown.Item
                    value="assistantName"
                    onClick={() => {
                      const list = [...fieldList]
                      list[5] = 'assistantName'
                      list[6] = 'Name_en'
                      list[7] = 'Name_ko'
                      list[8] = 'Name_ja'
                      list[9] = 'Name_es'
                      list[10] = 'Name_pt'

                      setFieldList(list)
                    }}
                  >
                    assistantName
                  </Dropdown.Item>
                  <Dropdown.Item
                    value="intro"
                    onClick={() => {
                      const list = [...fieldList]
                      list[5] = 'intro'
                      list[6] = 'intro_en'
                      list[7] = 'intro_ko'
                      list[8] = 'intro_ja'
                      list[9] = 'intro_es'
                      list[10] = 'intro_pt'

                      setFieldList(list)
                    }}
                  >
                    intro
                  </Dropdown.Item>
                </DropdownButton>
                <InputGroup onSubmit={handleSubmit}>
                  <FormControl
                    type="text"
                    value={search}
                    placeholder="Search"
                    onChange={event => setSearch(event.target.value)}
                    onKeyPress={keyEvent}
                  />
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    style={{ height: '38px' }}
                  >
                    <i className="fa fa-search" />
                  </Button>
                </InputGroup>
                {renderPaging(pageNumber, pageMap)}
              </div>
            </Row>
          </Col>
          <Row>
            <Box>
              <BoxInfo>
                <BoxHeader width={'20%'} style={{ height: '40px' }} />
                <BoxBody>{dataComponent()}</BoxBody>
              </BoxInfo>
            </Box>
          </Row>
        </Content>
      </>
    )
  }
}

export default withRouter(assistant)

const Table = styled.table`
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;

  td {
    border-top: 1px solid #f4f4f4;
  }
`

const Field = styled.th`
  border-top: 1px solid #f4f4f4;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  text-align: left;
`

const Content = styled.section`
  min-height: 250px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: block;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`

const Box = styled.section`
  min-height: 100px;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: block;
`

const BoxInfo = styled.div`
  border-top-color: #00c0ef;
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  display: block;
`

const BoxHeader = styled.div`
  position: relative;
  display: table;
  border-collapse: separate;
  width: ${props => (props.width ? props.width : '100%')};
  margin: 10px;
`

const BoxBody = styled.div`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
  min-height: 0.01%;
  overflow-x: auto;
`