import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import PageTitle from '../../../_Components/Common/PageTitle/PageTitle'
import Version from './Version'
import Preview from './Preview/Preview'
import EditTable from './Edit/EditTable'

const Banner = () => {
  const [page, setPage] = useState('home')
  const [lang, setLang] = useState('en')
  const [filter, setFilter] = useState('all')
  const [version, setVersion] = useState(null)
  const [newV, setNewV] = useState([])
  const [liveV, setLiveV] = useState([])
  const [prevV, setPrevV] = useState([])
  const [vLoading, setVLoading] = useState(false)
  const [edit, setEdit] = useState('')
  const [create, setCreate] = useState(false)

  const pageList = ['home']
  const langList = [
    { code: 'en', lang: 'English' },
    { code: 'ko', lang: 'Korean' },
    { code: 'ja', lang: 'Japaness' },
  ]
  const filterList = ['all', 'female', 'male']

  useEffect(() => {
    getVersion('', true)
  }, [page, lang])

  useEffect(() => {
    if (version) {
      getVersion(version.versionId, false)
    }
  }, [filter])

  useEffect(() => {
    setEdit('')
    setCreate(false)
  }, [version])

  const getVersion = async (id, all) => {
    setVLoading(true)
    await axios
      .get(
        `/new/webAdmin/v2/royal/collection/version/${
          all ? 'all' : id
        }?lang=${lang}&page=${page}&filter=${filter}`,
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(({ data }) => {
        if (all) {
          if (data.result.length === 0) {
            setLiveV([])
            setNewV([])
            setPrevV([])
            setVersion(null)
          } else {
            setLiveV(data.result.filter(v => v.status === 'Y'))
            setNewV(data.result.filter(v => v.status === 'N'))
            setPrevV(data.result.filter(v => v.status === 'P'))

            if (id) {
              setVersion(data.result.filter(v => v.versionId === id)[0])
            } else {
              if (data.result.filter(v => v.status === 'Y').length > 0) {
                setVersion(data.result.filter(v => v.status === 'Y')[0])
              } else {
                setVersion(data.result[0])
              }
            }
          }
        } else {
          setVersion(data.result[0])
        }

        setVLoading(false)
      })
  }

  return (
    <>
      <PageTitle
        mainTitle={'RoyalChat Banner'}
        v1Link={''}
        navNames={['RoyalChat', 'Banner']}
      />
      <SpaceResponsive height={10} />
      <Wrapper>
        <Version
          vLoading={vLoading}
          version={version}
          lang={lang}
          setLang={setLang}
          newV={newV}
          liveV={liveV}
          prevV={prevV}
          getVersion={getVersion}
        />
        <CurationWrapper>
          <CurationContent>
            <Preview
              version={version}
              pageList={pageList}
              langList={langList}
              filterList={filterList}
              page={page}
              setPage={setPage}
              lang={lang}
              setLang={setLang}
              filter={filter}
              setFilter={setFilter}
            />
            <EditTable
              version={version}
              getVersion={getVersion}
              edit={edit}
              setEdit={setEdit}
              create={create}
              setCreate={setCreate}
              page={page}
              lang={lang}
              filter={filter}
            />
          </CurationContent>
        </CurationWrapper>
      </Wrapper>
    </>
  )
}

export default Banner

const SpaceResponsive = styled.div`
  height: ${props => props.height}px;
  flex-shrink: 0;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  column-gap: 16px;
  padding: 0 15px;
  box-sizing: border-box;
`

const CurationWrapper = styled.div`
  width: 100%;
  height: calc(100dvh - 150px);
  display: flex;
  flex-direction: column;
`

const CurationContent = styled.div`
  width: 100%;
  display: flex;
  column-gap: 16px;
`
