import React from "react";
import AssistantEdit from "../../../Components/Royal/Assistant/AssistantEdit";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const AssistantEditPage = ({ match, location }) => {
  
    // const query = queryString.parse(location.search);
  
    return (
        
        <React.Fragment>
        {/* <LeftMenu /> */}
            <AssistantEdit />
        </React.Fragment>

    );
};

export default AssistantEditPage;
