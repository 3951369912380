import React from "react";
import ReactExport from "react-export-excel";
import { withRouter } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import { Modal, Table } from "react-bootstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      pay: 0,
      u_id: 0,
      date: this.props.date ? this.props.date : "",
      a_paypal: "",
      bank_name: "",
      bank_u_name: "",
      account_number: "",
      password: "",
      license: "",
      sort: "",
      searchKeyword: "",
      searchCri: "",
      idList: [],
      history: null,
      paypalHistory: null,
      show: false,
      show2: false,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("token")) {
      this._getPayment("all", "");
      // this.setState({isLoading:false});
    } else {
      this.props.history.push("/");
    }
  }

  _getPayment = (payment, sort) => {
    this.setState({ isLoading: true });
    const params = "?payment=" + payment;
    fetch("/new/webAdmin/v2/payment" + params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          paymentList: responseJson.result.paymentList,
          isLoading: false,
          idList: [],
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _paymentHistory = (u_id) => {
    fetch("/new/webAdmin/v2/payment/" + u_id, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          history: responseJson.result.history,
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _paypalHistory = (u_id) => {
    console.log(u_id);
    fetch("/new/webAdmin/v2/paypal/" + u_id, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: sessionStorage.getItem("token"),
      },
      method: "get",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
        this.setState({
          paypalHistory: responseJson.result,
        });
      })
      .catch((error) => {
        console.error("123=" + error);
      });
  };

  _historyRender = () => {
    const { history } = this.state;

    let list = [];

    if (history) {
      for (const data of history) {
        list.push(
          <>
            <div className="col-md-6">
              <div className="form-group">
                <label>지급금액</label>
                <br />
                <span>{"$" + data.PAYMENT}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>지급일</label>
                <br />
                <span>{data.PAY_DATE}</span>
              </div>
            </div>
            {data.PAYOUT_ID && (
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this._payCancel(data.PAYOUT_ID)}
                >
                  취소
                </button>
              </div>
            )}
          </>
        );
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  _historyPaypalRender = () => {
    const { paypalHistory } = this.state;

    let list = [];

    if (paypalHistory) {
      for (const data of paypalHistory) {
        list.push(
          <>
            <div className="col-md-6" key={data.seq}>
              <div className="form-group">
                <label>지급금액</label>
                <br />
                <span>{"$" + data.pay}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>지급일</label>
                <br />
                <span>{data.regDate}</span>
              </div>
            </div>
          </>
        );
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  _paymentRender = () => {
    const { paymentList } = this.state;

    let list = [];

    if (paymentList) {
      for (const data of paymentList) {
        list.push(
          <tr key={data.u_id}>
            <td width="5%" style={{ wordBreak: "break-all" }}>
              {data.u_id}
            </td>
            <td width="2%" style={{ wordBreak: "break-all" }}>
              <input
                type="checkbox"
                onChange={this._checkBoxEvent}
                value={data.u_id}
              />
            </td>
            <td width="9%" style={{ wordBreak: "break-all" }}>
              {data.u_name}
            </td>
            <td
              width="8%"
              style={{ wordBreak: "break-all" }}
              onClick={() => {
                this.setState(
                  {
                    u_id: data.u_id,
                    license: data.license,
                    pay: "",
                    a_paypal: data.a_paypal ? data.a_paypal : "",
                    bank_name: data.bank_name ? data.bank_name : "",
                    bank_u_name: data.bank_u_name ? data.bank_u_name : "",
                    account_number: data.account_number
                      ? data.account_number
                      : "",
                  },
                  () => this._paymentHistory(data.u_id)
                );
                this.handleClick();
              }}
            >
              {data.u_email}
            </td>
            <td width="7%" style={{ wordBreak: "break-all" }}>
              ${data.sale}
            </td>
            <td width="7%" style={{ wordBreak: "break-all" }}>
              ${data.ad}
            </td>
            <td width="7%" style={{ wordBreak: "break-all" }}>
              ${data.b2b}
            </td>
            <td width="7%" style={{ wordBreak: "break-all" }}>
              ${data.miri}
            </td>
            <td width="7%" style={{ wordBreak: "break-all" }}>
              ${data.subscription}
            </td>
            <td width="7%" style={{ wordBreak: "break-all" }}>
              ${data.artistSubscription}
            </td>
            <td width="5%" style={{ wordBreak: "break-all" }}>
              ${data.saleTotal}
            </td>
            <td width="5%" style={{ wordBreak: "break-all" }}>
              ${data.adTotal}
            </td>
            <td width="5%" style={{ wordBreak: "break-all" }}>
              ${data.b2bTotal}
            </td>
            <td width="5%" style={{ wordBreak: "break-all" }}>
              ${data.miriTotal}
            </td>
            <td width="5%" style={{ wordBreak: "break-all" }}>
              ${data.subscriptionTotal}
            </td>
            <td width="5%" style={{ wordBreak: "break-all" }}>
              ${data.artistSubscriptionTotal}
            </td>
            <td width="5%" style={{ wordBreak: "break-all" }}>
              ${data.coinTotal}
            </td>
            <td width="5%" style={{ wordBreak: "break-all" }}>
              ${data.paymentTotal}
            </td>
            <td width="5%" style={{ wordBreak: "break-all" }}>
              ${data.total}
            </td>
            <td width="5%" style={{ wordBreak: "break-all" }}>
              ${(data.total - data.paymentTotal).toFixed(2)}
            </td>
            {/* <td width="4%" style={{wordBreak:"break-all"}}>{(data.total - data.paymentTotal).toFixed(2) >= 50 ? "Y":"N"}</td> */}
            <td width="8%" style={{ wordBreak: "break-all" }}>
              {data.a_paypal ? data.a_paypal : "미입력"}
            </td>
            <td width="8%" style={{ wordBreak: "break-all" }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  this.setState(
                    {
                      u_id: data.u_id,
                      pay: "",
                      a_paypal: data.a_paypal ? data.a_paypal : "",
                    },
                    () => {
                      this.handleClick2();
                    }
                  );
                }}
              >
                지급
              </button>
            </td>
          </tr>
        );
      }
      return list;
    } else {
      return <div></div>;
    }
  };

  _checkBoxEvent = (event) => {
    const { idList } = this.state;
    const { value, checked } = event.target;

    if (checked) {
      this.setState({ idList: idList.concat(value) });
    } else {
      this.setState({ idList: idList.filter((id) => id !== value) });
    }
  };

  _updateSort = (sort) => {
    if (this.state.idList.length !== 0) {
      const params = {
        idList: this.state.idList,
        sort: sort,
        date: this.state.date,
      };
      fetch("/new/webAdmin/v2/after/sort", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "PUT",
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");
          if (responseJson.status === "success") {
            // this._getAfter(this.state.date, "");
            window.location.reload();
          } else {
            alert("실패");
          }
        })
        .catch((error) => {
          console.error("123=" + error);
        });
    }
  };

  _dateRender = () => {
    let list = [];
    if (this.state.category) {
      for (const data of this.state.category) {
        list.push(
          <option key={data.date} value={data.date}>
            {data.date}
          </option>
        );
      }
      return list;
    }
  };

  _pay = async () => {
    if (this.state.pay > 0) {
      const params = {
        u_id: this.state.u_id,
        pay: this.state.pay,
        paypal: this.state.a_paypal,
      };

      try {
        const response = await fetch("/new/webAdmin/v2/paypal", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            token: sessionStorage.getItem("token"),
          },
          method: "POST",
          body: JSON.stringify(params),
        });

        const responseJson = await response.json();
        if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");

        if (responseJson.status === "success") {
        } else {
          alert("실패");
        }
      } catch (error) {
        console.error("error=", error);
      }
    } else {
      alert("지불금액 입력!");
    }
  };

  _payCancel = async (payoutId) => {
    const params = {
      payoutId,
    };

    try {
      const response = await fetch("/new/webAdmin/v2/paypal/cancel", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: sessionStorage.getItem("token"),
        },
        method: "POST",
        body: JSON.stringify(params),
      });

      const responseJson = await response.json();
      if (responseJson.status === "fail" && responseJson.code === "1111") this.props.history.push("/");

      if (responseJson.status === "success") {
      } else {
        alert("실패");
      }
    } catch (error) {
      console.error("error=", error);
    }
  };

  _handleChangeInput = (e) => {
    this.setState({ searchKeyword: e.target.value.trim() });
  };

  _handleChangeSearchCri = (e) => {
    this.setState({ searchCri: e.target.value });
  };

  handleClick = () => {
    this.setState({ show: !this.state.show });
  };

  handleClick2 = () => {
    this.setState({ show2: !this.state.show2 });
  };

  render() {
    const { paymentList, show, show2 } = this.state;

    if (!this.state.isLoading) {
      return (
        <div
          // className="content-wrapper"
          style={{ minHeight: "100%" }}
        >
          <section className="content-header">
            <h1>지급 정산</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/ContentsList">
                  <i className="fa fa-dashboard"></i>정산
                </a>
              </li>
              <li className="active">정산 내역</li>
            </ol>
          </section>
          <section className="content">
            <div className="row">
              {/* <!-- Left col --> */}
              <section className="col-lg-12 connectedSortable">
                {/* <!-- Custom tabs (Charts with tabs)--> */}
                <div className="box box-info">
                  <div
                    className="box-header with-border"
                    style={{ paddingBottom: "30px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ marginRight: "10px" }}
                      onClick={() => this._getPayment("Y", "")}
                    >
                      $50 이상
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ marginRight: "10px" }}
                      onClick={() => this._getPayment("10", "")}
                    >
                      $10 이상
                    </button>
                    <ExcelFile>
                      <ExcelSheet data={paymentList} name="list">
                        <ExcelColumn label="u_id" value="u_id" />
                        <ExcelColumn label="작가명" value="u_name" />
                        <ExcelColumn label="이메일" value="u_email" />
                        <ExcelColumn label="최근 판매수익" value="sale" />
                        <ExcelColumn label="최근 광고수익" value="ad" />
                        <ExcelColumn label="최근 b2b수익" value="b2b" />
                        <ExcelColumn label="최근 miri수익" value="miri" />
                        <ExcelColumn label="최근 구독수익" value="subscription" />
                        <ExcelColumn label="최근 작가개인구독수익" value="artistSubscription" />
                        <ExcelColumn label="총 판매수익" value="saleTotal" />
                        <ExcelColumn label="총 광고수익" value="adTotal" />
                        <ExcelColumn label="총 B2B수익" value="b2bTotal" />
                        <ExcelColumn label="총 MIRI수익" value="miriTotal" />
                        <ExcelColumn label="총 구독수익" value="subscriptionTotal" />
                        <ExcelColumn label="총 작가개인구독수익" value="artistSubscriptionTotal" />
                        <ExcelColumn label="총 코인수익" value="coinTotal" />
                        <ExcelColumn label="총 지급내역" value="paymentTotal" />
                        <ExcelColumn label="총 수익" value="total" />
                        <ExcelColumn
                          label="미지급 금액"
                          value={(data) =>
                            (data.total - data.paymentTotal).toFixed(2)
                          }
                        />
                        <ExcelColumn
                          label="국가정보"
                          value={(data) =>
                            data.a_country === 410 ? "내국인" : "외국인"
                          }
                        />
                        <ExcelColumn label="은행이름" value="bank_name" />
                        <ExcelColumn label="예금주" value="bank_u_name" />
                        <ExcelColumn label="계좌번호" value="account_number" />
                        <ExcelColumn label="paypal" value="a_paypal" />
                        {/* <ExcelColumn label="라이센스" value={(data) => data.a_country === 410 ? data.license ? "라이센스업로드" : "라이센스업로드하지않음" : "외국인"} /> */}
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                  {/* <!-- /.box-header --> */}
                  <div className="box-body">
                    <Table bordered striped responsive>
                      <thead>
                        <tr>
                          <th width="5%">No</th>
                          <th width="2%"></th>
                          <th width="8%">작가 이름</th>
                          <th width="8%">작가 이메일</th>
                          <th width="8%">판매</th>
                          <th width="8%">광고</th>
                          <th width="8%">b2b</th>
                          <th width="8%">miri</th>
                          <th width="8%">구독</th>
                          <th width="8%">작가개인구독</th>
                          <th width="5%">총판매수익</th>
                          <th width="5%">총광고수익</th>
                          <th width="5%">총b2b수익</th>
                          <th width="5%">총miri수익</th>
                          <th width="5%">총구독수익</th>
                          <th width="5%">총작가개인구독수익</th>
                          <th width="5%">총코인수익</th>
                          <th width="5%">총지급내역</th>
                          <th width="5%">총 수익</th>
                          <th width="5%">미지급액</th>
                          {/* <th width="4%">$50 이상</th> */}
                          {/* <th width="6%">국가정보</th> */}
                          <th width="6%">페이팔</th>
                          <th width="5%">지급</th>
                          {/* <th width="9%">라이센스</th> */}
                        </tr>
                      </thead>
                      <tbody>{this._paymentRender()}</tbody>
                    </Table>
                  </div>
                </div>
              </section>
            </div>
          </section>

          <Modal show={show} onHide={this.handleClick} scrollable size="lg">
            <Modal.Header closeButton>
              <h4 className="modal-title" style={{ textAlign: "center" }}>
                상세정보
              </h4>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="row">
                  {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>은행명</label>
                                            <input type="text" className="form-control" value={this.state.bank_name} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label>계좌번호</label>
                                            <input type="text" className="form-control" value={this.state.account_number} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>예금주</label>
                                            <input type="text" className="form-control" value={this.state.bank_u_name} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label>paypal</label>
                                            <input type="text" className="form-control" value={this.state.a_paypal} disabled />
                                        </div>
                                    </div> */}
                  {this._historyRender()}
                </div>
                {/* <div className="form-group">
                                    <label>지급금액</label>
                                    <input type="number" className="form-control" placeholder="지급 금액" value={this.state.pay} onChange={(event) => this.setState({ pay: event.target.value })} />
                                </div> */}
                {/* <div className="form-group">
                                    <label>라이센스열람패스워드</label>
                                    <input type="password" className="form-control" placeholder="비밀번호입력" onChange={(event) => this.setState({ password: event.target.value })} />
                                </div> */}
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-default pull-left"
                onClick={this.handleClick}
              >
                닫기
              </button>
              {/* <button type="button" onClick={() => this._passwordCheck(this.state.password)} className="btn btn-danger">라이센스보기</button> */}
              {/* <button type="button" onClick={() => this._updatePay(this.state.u_id, this.state.pay, this.state.date)} className="btn btn-primary" data-dismiss="modal">지급</button> */}
            </Modal.Footer>
          </Modal>

          <Modal show={show2} onHide={this.handleClick2} scrollable size="lg">
            <Modal.Header closeButton>
              <h4 className="modal-title" style={{ textAlign: "center" }}>
                송금정보
              </h4>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>지급월 입력</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.a_paypal}
                        disabled
                      />
                    </div>
                    <div className="form-group">
                      <label>최종지급금액 입력</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.pay}
                        onChange={(event) =>
                          this.setState({ pay: event.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this._pay}
                      >
                        지급
                      </button>
                    </div>
                  </div>
                  {this._historyPaypalRender()}
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-default pull-left"
                onClick={this.handleClick2}
              >
                닫기
              </button>
              {/* <button type="button" onClick={() => alert("?")} className="btn btn-primary" data-dismiss="modal">지급</button> */}
            </Modal.Footer>
          </Modal>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default withRouter(Payment);
