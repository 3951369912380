import React from 'react'
import styled from 'styled-components'
import AiCard from './AiCard'
import Dropdown from '../../../NewApp/Dropdown'

const Preview = props => {
  const {
    version,
    pageList,
    langList,
    filterList,
    page,
    setPage,
    lang,
    setLang,
    filter,
    setFilter,
  } = props

  return (
    <Wrapper>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {version ? <Title>{version.version}</Title> : <Title>버전</Title>}
        <div style={{ height: '30px', margin: '15px 0', display: 'flex' }}>
          <Dropdown
            list={pageList}
            onChange={e => setPage(e.target.value)}
            value={page}
            type={'page'}
          />
          <div style={{ width: '10px' }} />
          <Dropdown
            list={langList}
            onChange={e => setLang(e.target.value)}
            value={lang}
            type={'lang'}
          />
          <div style={{ width: '10px' }} />
          <Dropdown
            list={filterList}
            onChange={e => setFilter(e.target.value)}
            value={filter}
            type={'page'}
          />
        </div>
      </div>
      <PreviewWrapper>
        {version && version.collection.length > 0 ? (
          version.collection.map((c, i) => (
            <AiCard
              key={i}
              title={c.title}
              assistantList={c.assistantList}
              lang={version.lang}
            />
          ))
        ) : (
          <></>
        )}
      </PreviewWrapper>
    </Wrapper>
  )
}

export default Preview

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  width: 100%;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
`

const PreviewWrapper = styled.div`
  width: 343px;
  height: calc(100dvh - 240px);
  border: 2px solid #000;
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
