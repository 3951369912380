import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import axios from 'axios'

const UserInfoPopup = props => {
  const { data, setData, open, setOpen } = props
  const [status, setStatus] = useState('active')
  const [more, setMore] = useState(false)
  const [livePageMap, setLivePageMap] = useState(null)
  const [hidePageMap, setHidePageMap] = useState(null)
  const [aiPageMap, setAiPageMap] = useState(null)
  const modalRef = useRef()

  useEffect(() => {
    getUserInfo(1, 1, 1)
  }, [])

  const getUserInfo = (live, hide, ai) => {
    const params = `?livePageNumber=${live}&hidePageNumber=${hide}&aiPageNumber=${ai}&limit=20`
    axios
      .get(`/new/webAdmin/v2/user/${data.u_id}` + params, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(res => {
        const info = res.data.result

        setLivePageMap(info.mySticker.pageMap)
        setHidePageMap(info.hideSticker.pageMap)
        setAiPageMap(info.aiSticker.pageMap)

        setData({
          ...data,
          downloadCnt: info.info.downloadCnt,
          sendCnt: info.info.sendCnt,
          artistYN: info.info.artistYN,
          isSubscribed:
            info.info.isTrial === 'Y'
              ? 'Trial'
              : info.info.cancelYN === 'Y'
              ? 'Cancel'
              : info.info.isSubscribed === 'Y'
              ? info.info.product === 'month'
                ? `Monthly-iMessage`
                : 'Yearly-iMessage'
              : info.info.isSubscribedChatpro === 'Y'
              ? info.info.chatproProduct === 'month'
                ? `Monthly-Chatpro`
                : 'Yearly-Chatpro'
              : info.info.isSubscribedBasic === 'Y'
              ? info.info.basicProduct === 'month'
                ? `Monthly-Basic`
                : 'Yearly-Basic'
              : info.info.isSubscribedStandard === 'Y'
              ? info.info.standardProduct === 'month'
                ? `Monthly-Standard`
                : 'Yearly-Standard'
              : info.info.isSubscribedPremium === 'Y'
              ? info.info.premiumProduct === 'month'
                ? `Monthly-Premium`
                : 'Yearly-Premium'
              : 'N',
          product:
            info.info.isSubscribed === 'Y'
              ? info.info.product
              : info.info.isSubscribedChatpro === 'Y'
              ? info.info.chatproProduct
              : info.info.isSubscribedBasic === 'Y'
              ? info.info.basicProduct
              : info.info.isSubscribedStandard === 'Y'
              ? info.info.standardProduct
              : info.info.isSubscribedPremium === 'Y'
              ? info.info.premiumProduct
              : null,
          cancelYN: info.info.cancelYN,
          isTrial: info.info.isTrial,
          appleProductId: info.info.appleProductId,
          // payHistory: [
          //   ...info.applePayHistory.map(item => {
          //     return { ...item, date: item.SIGNED_DATE }
          //   }),
          //   ...info.paypalPayHistory,
          // ].sort((a, b) => new Date(b.date) - new Date(a.date)),
          payHistory:
            info.applePayHistory.length > 0
              ? [
                  ...info.applePayHistory.map(item => {
                    return { ...item, date: item.SIGNED_DATE }
                  }),
                ]
              : info.paypalPayHistory,
          myStickers: info.mySticker.stickers,
          hideStickers: info.hideSticker.stickers,
          aiStickers: info.aiSticker.stickers,
        })

        console.log(info)
      })
  }

  useEffect(() => {
    console.log(data)
  }, [data])

  const renderPaging = (status, pageMap) => {
    let list = []
    let j = 0
    let i = 0

    const first = pageMap.pageNumber !== 1 ? pageMap.pageNumber - 1 : 1
    const last =
      pageMap.pageNumber !== pageMap.pageCount
        ? pageMap.pageNumber * 1 + 1
        : pageMap.pageCount

    list.push(
      <PaginationItem
        key="first"
        onClick={() => {
          if (status === 'active') {
            getUserInfo(first, 1, 1)
          } else if (status === 'hidden') {
            getUserInfo(1, first, 1)
          } else {
            getUserInfo(1, 1, first)
          }
        }}
      >
        &laquo;
      </PaginationItem>
    )

    for (i = pageMap.startPage * 1; i <= pageMap.endPage; i++) {
      let num = pageMap.startPage * 1 + j

      if (num === pageMap.pageNumber * 1) {
        list.push(
          <PaginationItem
            key={i}
            style={{ color: '#fff', backgroundColor: '#007bff' }}
          >
            {num}
          </PaginationItem>
        )
      } else {
        list.push(
          <PaginationItem
            key={i}
            onClick={() => {
              if (status === 'active') {
                getUserInfo(num, 1, 1)
              } else if (status === 'hidden') {
                getUserInfo(1, num, 1)
              } else {
                getUserInfo(1, 1, num)
              }
            }}
          >
            {num}
          </PaginationItem>
        )
      }
      j++
    }

    list.push(
      <PaginationItem
        key="last"
        onClick={() => {
          if (status === 'active') {
            getUserInfo(last, 1, 1)
          } else if (status === 'hidden') {
            getUserInfo(1, last, 1)
          } else {
            getUserInfo(1, 1, last)
          }
        }}
      >
        &raquo;
      </PaginationItem>
    )

    return <PaginationWrapper>{list}</PaginationWrapper>
  }

  return (
    <Wrapper
      onClick={e => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
          setOpen(false)
        }
      }}
    >
      <ModalWrapper>
        <ModalComponent ref={modalRef}>
          <ModalHeader>{data.u_name}</ModalHeader>
          <ModalBody>
            <Label>사용자 정보</Label>
            <UserInfo>
              <div className="user-info">
                <span>ID : {data.u_id}</span>
                <span>Name : {data.u_name}</span>
                <span>Email : {data.u_email}</span>
                <span>Join : {data.u_date}</span>
                <span>
                  가입경로 :{' '}
                  {data.u_sns === 'gle'
                    ? 'google'
                    : data.u_sns === 'apple'
                    ? 'apple'
                    : data.u_sns === 'fb'
                    ? 'facebook'
                    : 'email'}
                </span>
              </div>
              <div className="user-info">
                <span>현재 구독 상태 : {data.isSubscribed}</span>
                <span>누적 팩 다운로드 수 : {data.downloadCnt}</span>
                <span>누적 스티커 전송 수 : {data.sendCnt}</span>
                <span>작가 여부 : {data.artistYN === 'Y' ? 'O' : 'X'}</span>
                <span>국적 : </span>
              </div>
            </UserInfo>
            <Label>구독 로그</Label>
            <PayHistory>
              {data.payHistory && data.payHistory.length > 0 && (
                <Table>
                  <thead>
                    <tr>
                      <th>액션</th>
                      <th>날짜</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.payHistory.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.NOTIFICATION_TYPE
                            ? item.NOTIFICATION_TYPE ===
                              'DID_CHANGE_RENEWAL_STATUS'
                              ? item.NOTIFICATION_SUBTYPE
                              : item.NOTIFICATION_TYPE
                            : item.amount === 0
                            ? item.payment === 'coupon'
                              ? '쿠폰'
                              : '무료 체험'
                            : item.plan === 'year'
                            ? '1년 구독'
                            : item.plan === 'month' && '1개월 구독'}
                        </td>
                        <td>{new Date(item.date).toLocaleString()}</td>
                      </tr>
                    ))}
                    {/* {data.applePayHistory.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.NOTIFICATION_TYPE}
                          {item.NOTIFICATION_SUBTYPE &&
                            ` / ${item.NOTIFICATION_SUBTYPE}`}
                        </td>
                        <td>{item.SIGNED_DATE}</td>
                      </tr>
                    ))}
                    {data.paypalPayHistory.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.amount === 0
                            ? item.payment === 'coupon'
                              ? '쿠폰'
                              : '무료 체험'
                            : item.plan === 'year'
                            ? '1년 구독'
                            : item.plan === 'month' && '1개월 구독'}
                        </td>
                        <td>{item.date}</td>
                      </tr>
                    ))} */}
                  </tbody>
                </Table>
              )}
            </PayHistory>
            <Label>
              My Stickers ({status === 'active' ? 'Active' : 'Hidden'})
            </Label>
            <Stickers>
              <StickerStatus>
                <div style={{ display: 'flex', height: '100%' }}>
                  <button
                    onClick={() => setStatus('active')}
                    style={{
                      backgroundColor: status === 'active' && '#007ffb',
                      color: status === 'active' && '#fff',
                    }}
                  >
                    Active
                  </button>
                  <button
                    onClick={() => setStatus('hidden')}
                    style={{
                      backgroundColor: status === 'hidden' && '#007ffb',
                      color: status === 'hidden' && '#fff',
                    }}
                  >
                    Hidden
                  </button>
                  <button
                    onClick={() => setStatus('ai')}
                    style={{
                      backgroundColor: status === 'ai' && '#007ffb',
                      color: status === 'ai' && '#fff',
                    }}
                  >
                    AI
                  </button>
                </div>
                {status === 'active'
                  ? data.myStickers &&
                    data.myStickers.length > 5 && (
                      <More more={more}>
                        <button onClick={() => setMore(!more)}>
                          {!more ? '더보기' : '줄이기'}
                        </button>
                      </More>
                    )
                  : status === 'hidden'
                  ? data.hideStickers &&
                    data.hideStickers.length > 5 && (
                      <More>
                        <button
                          onClick={() => {
                            setMore(!more)
                          }}
                        >
                          {!more ? '더보기' : '줄이기'}
                        </button>
                      </More>
                    )
                  : data.aiStickers &&
                    data.aiStickers.length > 5 && (
                      <More>
                        <button
                          onClick={() => {
                            setMore(!more)
                          }}
                        >
                          {!more ? '더보기' : '줄이기'}
                        </button>
                      </More>
                    )}
              </StickerStatus>
              <TableWrapper>
                <Table>
                  <thead>
                    <tr>
                      <th>패키지 ID</th>
                      <th>패키지명</th>
                      <th>{status === 'ai' ? '생성 날짜' : '작가'}</th>
                      <th>{status === 'ai' ? '공유 링크' : '다운로드 날짜'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {status === 'active'
                      ? data.myStickers &&
                        data.myStickers.length > 0 &&
                        data.myStickers.map((item, index) => {
                          if (!more) {
                            if (index < 5) {
                              return (
                                <tr key={item.packageId}>
                                  <td>{item.packageId}</td>
                                  <td>{item.packageName}</td>
                                  <td>{item.artistName}</td>
                                  <td>{item.downloadDate}</td>
                                </tr>
                              )
                            }
                          } else {
                            return (
                              <tr key={item.packageId}>
                                <td>{item.packageId}</td>
                                <td>{item.packageName}</td>
                                <td>{item.artistName}</td>
                                <td>{item.downloadDate}</td>
                              </tr>
                            )
                          }
                        })
                      : status === 'hidden'
                      ? data.hideStickers &&
                        data.hideStickers.length > 0 &&
                        data.hideStickers.map((item, index) => {
                          if (!more) {
                            if (index < 5) {
                              return (
                                <tr key={item.packageId}>
                                  <td>{item.packageId}</td>
                                  <td>{item.packageName}</td>
                                  <td>{item.artistName}</td>
                                  <td>{item.downloadDate}</td>
                                </tr>
                              )
                            }
                          } else {
                            return (
                              <tr key={item.packageId}>
                                <td>{item.packageId}</td>
                                <td>{item.packageName}</td>
                                <td>{item.artistName}</td>
                                <td>{item.downloadDate}</td>
                              </tr>
                            )
                          }
                        })
                      : data.aiStickers &&
                        data.aiStickers.length > 0 &&
                        data.aiStickers.map((item, index) => {
                          if (!more) {
                            if (index < 5) {
                              return (
                                <tr key={item.p_id}>
                                  <td>{item.p_id}</td>
                                  <td>{item.p_name}</td>
                                  <td>{item.p_commitDate}</td>
                                  <td>{`https://stipop.com/en/sticker-pack/${btoa(
                                    item.p_id
                                  )}`}</td>
                                </tr>
                              )
                            }
                          } else {
                            return (
                              <tr key={item.p_id}>
                                <td>{item.p_id}</td>
                                <td>{item.p_name}</td>
                                <td>{item.p_commitDate}</td>
                                <td>{`https://stipop.com/en/sticker-pack/${btoa(
                                  item.p_id
                                )}`}</td>
                              </tr>
                            )
                          }
                        })}
                  </tbody>
                </Table>
              </TableWrapper>
              {more &&
                (status === 'active'
                  ? renderPaging(status, livePageMap)
                  : status === 'hidden'
                  ? renderPaging(status, hidePageMap)
                  : renderPaging(status, aiPageMap))}
            </Stickers>
          </ModalBody>
        </ModalComponent>
      </ModalWrapper>
    </Wrapper>
  )
}

export default UserInfoPopup

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`
const ModalWrapper = styled.div`
  width: 800px;
  min-height: calc(100% - 3.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalComponent = styled.div`
  width: 100%;
  max-height: 920px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const ModalHeader = styled.div`
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #dee2e6;
  padding: 16px;
  font-size: 24px;
  font-weight: bold;
`

const ModalBody = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
`

const Label = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`

const UserInfo = styled.div`
  width: 100%;
  display: flex;

  & .user-info {
    width: 100%;
    display: flex;
    flex-direction: column;

    span {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
`

const PayHistory = styled.div`
  width: 100%;
  min-height: 50px;
  max-height: 150px;
  overflow-y: auto;
  margin-bottom: 16px;
`

const Table = styled.table`
  width: 100%;

  thead {
    border-top: 1px solid #f4f4f4;
    border-bottom: 2px solid #f4f4f4;

    tr > th {
      padding: 12px;
    }
  }

  tbody {
    tr > td {
      padding: 6px 12px;
    }
  }
`

const Stickers = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

const StickerStatus = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  button {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
    margin-right: 5px;
  }
`

const TableWrapper = styled.div`
  width: 100%;
  min-height: 50px;
`

const More = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: 10%;
    height: 100%;
    border: none;
    border-radius: 5px;
    background-color: ${props => props.more && '#007ffb'};
    color: ${props => props.more && '#fff'};
  }
`

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`

const PaginationItem = styled.div`
  width: 30px;
  height: 100%;
  border: none;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 2px;

  &:hover {
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
  }
`
