import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import EditCuration from './EditCuration'
import NewCuration from './NewCuration'

const EditTable = props => {
  const {
    version,
    getVersion,
    edit,
    setEdit,
    create,
    setCreate,
    page,
    lang,
    filter,
  } = props

  const [data, setData] = useState([])
  const [checked, setChecked] = useState([])
  const [drag, setDrag] = useState(false)

  const dragDataInit = {
    target: null,
    index: -1,
    move_up: [],
    move_down: [],
    updateList: [],
  }

  const [dragData, setDragData] = useState(dragDataInit)
  const [isScroll, setIsScroll] = useState('')
  const [posY, setPosY] = useState(0)

  const checkRef = useRef([])
  const wrapperRef = useRef()
  const editRef = useRef()
  const newRef = useRef()

  useEffect(() => {
    if (version) {
      setData(version.collection)
    } else {
      setData([])
    }
  }, [version])

  useEffect(() => {
    const scrollHeight = wrapperRef.current.scrollTop
    const posH = wrapperRef.current.getBoundingClientRect().height

    if (isScroll === 'down') {
      wrapperRef.current.scrollTo({
        top: scrollHeight + posH - posY * 2,
        behavior: 'smooth',
      })
    }

    if (isScroll === 'up') {
      wrapperRef.current.scrollTo({
        top: scrollHeight - posH + posY * 2,
        behavior: 'smooth',
      })
    }
  }, [isScroll])

  const handleCheck = e => {
    if (e.target.checked) {
      setChecked(checked.concat(Number(e.target.value)))
    } else {
      setChecked(checked.filter(id => id !== Number(e.target.value)))
    }
  }

  const clickLive = async () => {
    await axios
      .put(
        `/new/webAdmin/v2/royal/collection/version/status`,
        { lang, versionId: version.versionId },
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(() => {
        getVersion(version.versionId, true)
      })
  }

  const clickDelete = async () => {
    if (checked.length > 0) {
      const params = {
        deleted: checked,
      }

      await axios
        .delete(`/new/webAdmin/v2/royal/collection`, {
          headers: {
            token: sessionStorage.getItem('token'),
          },
          data: params,
        })
        .then(() => {
          if (checkRef.current) {
            checkRef.current.forEach((c, i) => {
              if (c && c.checked) {
                c.checked = false
              }
            })
          }
          setChecked([])
          getVersion(version.versionId)
        })
    }
  }

  const reOrderPromise = async (id, order) => {
    const params = {
      collectionId: id,
      order: order,
    }

    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `/new/webAdmin/v2/royal/collection/order?filter=${filter}`,
          params,
          {
            headers: {
              token: sessionStorage.getItem('token'),
            },
          }
        )
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  const reOrder = async list => {
    const result = await Promise.all(
      list.map((item, index) => reOrderPromise(item.collectionId, index + 1))
    )

    if (result) {
      getVersion(version.versionId)
    }
  }

  const _dragStart = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    setDragData({
      ...dragData,
      target: e.currentTarget,
      index: Number(e.currentTarget.dataset.index),
      updateList: [...data],
    })

    e.dataTransfer.setData('text/html', '')
    e.dataTransfer.effectAllowed = 'move'
  }

  const _dragEnter = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    const _dragged = Number(dragData.target.dataset.index)
    const _target = Number(e.currentTarget.dataset.index)
    let move_down = [...dragData.move_down]
    let move_up = [...dragData.move_up]

    if (_dragged > _target) {
      if (move_down.includes(_target)) {
        move_down = move_down.slice(0, move_down.indexOf(_target))
      } else {
        move_down = Array(_dragged - _target)
          .fill()
          .map((v, i) => i + _target)
          .reverse()
      }
      move_up = []
    } else if (_dragged < _target) {
      if (move_up.includes(_target)) {
        move_up = move_up.slice(0, move_up.indexOf(_target))
      } else {
        move_up = Array(_target - _dragged)
          .fill()
          .map((v, i) => _target - i)
          .reverse()
      }
      move_down = []
    } else {
      move_down = []
      move_up = []
    }

    setDragData({
      ...dragData,
      // updateList: updateData,
      index: _target,
      move_up,
      move_down,
    })
  }

  const _dragLeave = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    if (e.currentTarget === dragData.target) {
      e.currentTarget.style.visibility = 'hidden'
    }
  }

  const _dragEnd = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    let updateData = [...dragData.updateList]

    const changeOrder = (i, t) => {
      const target = updateData.splice(i, 1)[0]
      updateData.splice(t, 0, target)
    }

    const _dragged = Number(e.currentTarget.dataset.index)
    let _target = Number(dragData.index)

    if (dragData.move_up.length > 0) {
      _target = dragData.move_up[dragData.move_up.length - 1]
    } else if (dragData.move_down.length > 0) {
      _target = dragData.move_down[dragData.move_down.length - 1]
    } else {
      _target = _dragged
    }

    changeOrder(_dragged, _target)

    setDrag(false)
    setData(updateData)
    reOrder(updateData)
    setDragData({
      ...dragData,
      move_down: [],
      move_up: [],
      updateList: [],
    })

    e.currentTarget.style.visibility = 'visible'
    e.dataTransfer.dropEffect = 'move'
  }

  const _dragCapture = async e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    const posH = e.target.getBoundingClientRect().height
    const bottom = wrapperRef.current.getBoundingClientRect().bottom
    const top = wrapperRef.current.getBoundingClientRect().top

    setPosY(posH)

    if (e.clientY && e.clientY > top && e.clientY < bottom) {
      if (bottom - e.clientY < 90) {
        setIsScroll('down')
      } else if (e.clientY - top < 90) {
        setIsScroll('up')
      } else {
        setIsScroll('')
      }
    }
  }

  return (
    <Wrapper>
      {!edit && !create ? (
        <>
          {version ? (
            version.status !== 'Y' ? (
              <div>
                <ButtonWrapper style={{ margin: 0 }}>
                  <button
                    style={{ backgroundColor: '#ff4500' }}
                    onClick={() => {
                      clickLive()
                    }}
                  >
                    라이브 출시
                  </button>
                </ButtonWrapper>
                <ButtonWrapper>
                  <button
                    style={{ backgroundColor: '#dc143c' }}
                    onClick={() => {
                      clickDelete()
                    }}
                  >
                    선택 삭제
                  </button>
                  <button
                    style={{ backgroundColor: '#007ffb' }}
                    onClick={() => {
                      setCreate(true)
                    }}
                  >
                    새 배너 추가
                  </button>
                </ButtonWrapper>
              </div>
            ) : (
              <SpaceResponsive height={90} />
            )
          ) : (
            <></>
          )}
          <CollectionWrapper ref={wrapperRef}>
            {data.length > 0 &&
              data.map((item, index) => {
                let default_class = ''
                dragData.move_down.includes(index) &&
                  (default_class = 'move_down')
                dragData.move_up.includes(index) && (default_class = 'move_up')
                return (
                  <CollectionItem
                    key={index}
                    data={item}
                    data-index={index}
                    draggable={drag}
                    onDragStart={_dragStart}
                    onDragEnter={_dragEnter}
                    onDragLeave={_dragLeave}
                    onDragEnd={_dragEnd}
                    onDragCapture={_dragCapture}
                    className={default_class}
                    live={version && version.status === 'Y'}
                  >
                    <div style={{ width: '5%' }}>
                      <input
                        ref={el => (checkRef.current[index] = el)}
                        type="checkbox"
                        value={item.collectionId}
                        onChange={e => {
                          handleCheck(e)
                        }}
                      />
                    </div>
                    <div style={{ width: '10%' }}>#{index + 1}</div>
                    <div
                      style={{
                        width: '15%',
                        minWidth: '80px',
                        marginRight: '5px',
                        justifyContent: 'flex-start',
                        fontWeight: 'bold',
                      }}
                    >
                      {item.type}
                    </div>
                    <div
                      style={{
                        width: '60%',
                        justifyContent: 'flex-start',
                        fontWeight: 'bold',
                      }}
                    >
                      {item.title !== 'null' ? item.title : ''}
                    </div>
                    <div style={{ width: '15%', justifyContent: 'flex-end' }}>
                      {version && version.status !== 'Y' && (
                        <button
                          id="edit-button"
                          onClick={e => {
                            setEdit(item.collectionId)
                          }}
                          style={{ marginRight: '15px' }}
                        >
                          Edit
                        </button>
                      )}
                      <img
                        id="drag"
                        src="https://img.stipop.io/image/account/hamburger_menu.png"
                        alt=""
                        draggable={false}
                        onMouseDown={() => {
                          setDrag(true)
                        }}
                      />
                    </div>
                  </CollectionItem>
                )
              })}
          </CollectionWrapper>
        </>
      ) : (
        <>
          {version ? (
            version.status !== 'Y' ? (
              <div>
                <SpaceResponsive height={35} />
                <ButtonWrapper>
                  <button
                    style={{ backgroundColor: '#818181' }}
                    onClick={() => {
                      getVersion(version.versionId, false)
                    }}
                  >
                    목록으로
                  </button>
                  <button
                    style={{ backgroundColor: '#dc143c' }}
                    onClick={() => {
                      if (edit) {
                        editRef.current.clickDelete()
                      } else if (create) {
                        newRef.current.clickDelete()
                      }
                    }}
                  >
                    선택 삭제
                  </button>
                  <button
                    style={{ backgroundColor: '#ff4500' }}
                    onClick={() => {
                      if (edit) {
                        editRef.current.clickAdd()
                      } else if (create) {
                        newRef.current.clickAdd()
                      }
                    }}
                  >
                    새 구간 추가
                  </button>
                  <button
                    style={{ backgroundColor: '#007ffb' }}
                    onClick={() => {
                      if (edit) {
                        editRef.current.clickUpdate()
                      } else if (create) {
                        newRef.current.clickNew()
                      }
                    }}
                  >
                    배너 저장
                  </button>
                </ButtonWrapper>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          <CollectionWrapper>
            {edit && (
              <EditCuration
                ref={editRef}
                version={version}
                collectionData={data}
                setCollectionData={setData}
                collectionId={edit}
                setEdit={setEdit}
                getVersion={getVersion}
              />
            )}
            {create && (
              <NewCuration
                ref={newRef}
                version={version}
                getVersion={getVersion}
                page={page}
              />
            )}
          </CollectionWrapper>
        </>
      )}
    </Wrapper>
  )
}

export default EditTable

const SpaceResponsive = styled.div`
  height: ${props => props.height}px;
  flex-shrink: 0;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const CollectionWrapper = styled.div`
  width: 100%;
  min-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  row-gap: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;

  button {
    height: 35px;
    padding: 0 10px;
    box-sizing: border-box;
    margin-left: 10px;
    color: #fff;
    border-radius: 5px;
    border: none;

    &:hover {
      opacity: 0.8;
      font-weight: bold;
    }
  }
`

const CollectionItem = styled.div`
  width: 100%;
  height: 80px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid ${props => (props.live ? '#007ffb' : '#000')};
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  position: relative;
  opacity: 0.999;

  div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #edit-button {
    height: 30px;
    border: none;
    background-color: #007ffb;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 5px;

    &:hover {
      opacity: 0.8;
    }
  }

  #drag {
    width: 25px;
    height: 20px;
    cursor: pointer;
  }

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }

  &.move_up {
    transform: translateY(-90px);
    z-index: 1;
  }
  &.move_down {
    transform: translateY(90px);
    z-index: 1;
  }
`
