import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Dropdown = styled.div`
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
`;


const Componeent = ({model, modelList, setModel}) => {
    return (
        <>
            <Dropdown>
                <select onChange={(e) => setModel(e.target.value)} >
                    {
                        model === "" && <option key={999} value={''}>모델선택</option>
                    }
                    {
                        modelList && modelList.map((model, i) => <option key={i} value={model.id}>{model.id}</option> )
                    }
                </select>
            </Dropdown>
        </>
    )
};

const Lang = (model, modelList, setModel) => {
    
    const [state, setState] = useState(model);

    return [
        <Componeent model={state} modelList={modelList} setModel={setModel} />
        ,setState
    ]

};

export default Lang;