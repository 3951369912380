import React from "react";
import AssistantDev from "../../Components/AssistantDev/AssistantDev";
// import LeftMenu from "../../Components/LeftMenu";
import queryString from "query-string";

const AssistantDevPage = ({ match, location }) => {
    
    const query = queryString.parse(location.search);
    
    return (
        <React.Fragment>
        {/* <LeftMenu /> */}
        <AssistantDev
            pageNumber={query.pageNumber}
            search={query.search ? query.search : ""}
        />
        </React.Fragment>
    );
};

export default AssistantDevPage;
