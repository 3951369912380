import React from "react";
import styled from "styled-components";
import LeftMenuBox from "./LeftMenuBox";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import {
  DataMenuList,
  ManagementMenuList,
  SettlementMenuList,
  B2BMenuList,
  trackingMenuList,
  OtherMenuList,
  RoyalChatMenuList
} from "../../Data/LeftMenuListData";

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  color: white;
  user-select: none;
`;

const Logo = styled.div`
  z-index: 10;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  min-width: 230px;
  height: 50px;
  background-color: #357ca5;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
  padding-top: 50px;
  height: 100%;
  background-color: #222d32;
  color: white;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const LeftMenuSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 36px;
  height: 36px;
  background-color: #1a2226;
  color: #aaaaaa;
  font-size: 12px;
  padding-left: 15px;
`;

const LeftMenuContainer = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [pathname, setPathname] = useState("");
  const [isShown, setIsShown] = useState(0);

  const history = useHistory();

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      if (pathname === "") {
        setPathname(history.location.pathname);
      }
      history.listen((listen) => {
        setPathname(listen.pathname);
      });
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const onClickLogo = () => {
    history.push("/");
  };

  return (
    <Wrapper>
      <Logo onClick={onClickLogo}>StipopAdmin</Logo>
      {isLoggedIn && (
        <Inner>
          <LeftMenuSection>Data</LeftMenuSection>
          {DataMenuList &&
            DataMenuList.map((menu, index) => {
              return (
                <LeftMenuBox
                  key={index}
                  pathname={pathname}
                  isShown={isShown}
                  setIsShown={setIsShown}
                  {...menu}
                />
              );
            })}
          <LeftMenuSection>RoyalChat</LeftMenuSection>   
            {
              RoyalChatMenuList &&
              RoyalChatMenuList.map((menu, index) => {
                return (
                  <LeftMenuBox
                    key={index}
                    pathname={pathname}
                    isShown={isShown}
                    setIsShown={setIsShown}
                    {...menu}
                  />
                );
              })
            }
          <LeftMenuSection>Stipop</LeftMenuSection>
          {ManagementMenuList &&
            ManagementMenuList.map((menu, index) => {
              return (
                <LeftMenuBox
                  key={index}
                  pathname={pathname}
                  isShown={isShown}
                  setIsShown={setIsShown}
                  {...menu}
                />
              );
            })}                 
          <LeftMenuSection>정산</LeftMenuSection>{" "}
          {SettlementMenuList &&
            SettlementMenuList.map((menu, index) => {
              return (
                <LeftMenuBox
                  key={index}
                  pathname={pathname}
                  isShown={isShown}
                  setIsShown={setIsShown}
                  {...menu}
                />
              );
            })}
          <LeftMenuSection>B2B</LeftMenuSection>
          {B2BMenuList &&
            B2BMenuList.map((menu, index) => {
              return (
                <LeftMenuBox
                  key={index}
                  pathname={pathname}
                  isShown={isShown}
                  setIsShown={setIsShown}
                  {...menu}
                />
              );
            })}
          <LeftMenuSection>트래킹</LeftMenuSection>
          {trackingMenuList &&
            trackingMenuList.map((menu, index) => {
              return (
                <LeftMenuBox
                  key={index}
                  pathname={pathname}
                  isShown={isShown}
                  setIsShown={setIsShown}
                  {...menu}
                />
              );
            })}
          <LeftMenuSection>Logout</LeftMenuSection>
          {OtherMenuList &&
            OtherMenuList.map((menu, index) => {
              return (
                <LeftMenuBox
                  key={index}
                  pathname={pathname}
                  isShown={isShown}
                  setIsShown={setIsShown}
                  {...menu}
                />
              );
            })}
        </Inner>
      )}
    </Wrapper>
  );
};

export default LeftMenuContainer;
