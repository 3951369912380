import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import LoadingSpinner from '../../Common/Loading/LoadingSpinner'
import Popup from '../../../Pages/Royal/Popup/Popup'

const Version = props => {
  const { vLoading, version, lang, newV, liveV, prevV, getVersion } = props

  const [create, setCreate] = useState(false)
  const [name, setName] = useState('')
  const [edit, setEdit] = useState(null)
  const [copyLangList, setCopyLangList] = useState([])
  const [popup, setPopup] = useState('')

  useEffect(() => {
    setCreate(false)
    setName('')
  }, [vLoading])

  useEffect(() => {
    setName('')
    setEdit(null)
  }, [version])

  useEffect(() => {
    if (edit) {
      setCreate(false)
      setName(edit.version)
    }
  }, [edit])

  useEffect(() => {
    if (create) {
      setEdit(null)
      setName('')
    }
  }, [create])

  useEffect(() => {
    setCopyLangList([lang])
  }, [lang])

  const createVersion = async () => {
    await axios
      .post(
        `/new/webAdmin/v2/royal/collection/version`,
        {
          version: name,
          lang,
        },
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(({ data }) => {
        getVersion(data.result.versionId, true)
      })
  }

  const editVersion = async () => {
    await axios
      .put(
        `/new/webAdmin/v2/royal/collection/version`,
        {
          versionId: version.versionId,
          version: name,
        },
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(() => {
        getVersion(version.versionId, true)
      })
  }

  const deleteVersion = async () => {
    await axios
      .delete(`/new/webAdmin/v2/royal/collection/version`, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
        data: {
          versionId: version.versionId,
        },
      })
      .then(() => {
        getVersion('', true)
      })
  }

  const copyVersion = async version => {
    await axios
      .post(
        `/new/webAdmin/v2/royal/collection/version/copy`,
        {
          version: JSON.stringify(version),
          langList: copyLangList,
        },
        {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        }
      )
      .then(() => {
        setPopup('')
        getVersion(version.versionId, true)
      })
  }

  return (
    <Wrapper>
      {popup && (
        <Popup
          version={version}
          popup={popup}
          setPopup={setPopup}
          copyLangList={copyLangList}
          setCopyLangList={setCopyLangList}
          copyVersion={copyVersion}
        />
      )}
      {vLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <VersionWrapper>
            <Title>라이브 큐레이션</Title>
            {liveV.length > 0 &&
              liveV.map((item, index) => (
                <VersionItem
                  key={index}
                  active={version && version.versionId === item.versionId}
                >
                  {edit && edit.versionId === item.versionId ? (
                    <input
                      type="text"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  ) : (
                    <div
                      onClick={() => {
                        getVersion(item.versionId, false)
                      }}
                    >
                      - {item.version}
                    </div>
                  )}
                  {edit && edit.versionId === item.versionId ? (
                    <ButtonWrapper style={{ margin: '5px 0' }}>
                      <button
                        onClick={async () => {
                          await editVersion()
                        }}
                        style={{ backgroundColor: '#007ffb' }}
                      >
                        저장
                      </button>
                      <button
                        onClick={() => {
                          setEdit(null)
                        }}
                        style={{
                          backgroundColor: '#dc143c',
                          color: '#fff',
                        }}
                      >
                        닫기
                      </button>
                    </ButtonWrapper>
                  ) : (
                    version &&
                    version.versionId === item.versionId && (
                      <ButtonWrapper style={{ margin: '5px 0' }}>
                        <button
                          onClick={() => {
                            setEdit(item)
                          }}
                          style={{
                            backgroundColor: '#007ffb',
                          }}
                        >
                          수정
                        </button>
                        <button
                          onClick={() => {
                            setPopup('copy')
                          }}
                          style={{
                            backgroundColor: '#ff4500',
                          }}
                        >
                          복사
                        </button>
                      </ButtonWrapper>
                    )
                  )}
                </VersionItem>
              ))}
          </VersionWrapper>
          <VersionWrapper>
            <Title>새로운 큐레이션</Title>
            {newV.length > 0 &&
              newV.map((item, index) => (
                <VersionItem
                  key={index}
                  active={version && version.versionId === item.versionId}
                >
                  {edit && edit.versionId === item.versionId ? (
                    <input
                      type="text"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  ) : (
                    <div
                      onClick={() => {
                        getVersion(item.versionId, false)
                      }}
                    >
                      - {item.version}
                    </div>
                  )}
                  {edit && edit.versionId === item.versionId ? (
                    <ButtonWrapper style={{ margin: '5px 0' }}>
                      <button
                        onClick={async () => {
                          await editVersion()
                        }}
                        style={{ backgroundColor: '#007ffb' }}
                      >
                        저장
                      </button>
                      <button
                        onClick={() => {
                          setEdit(null)
                        }}
                        style={{
                          backgroundColor: '#dc143c',
                          color: '#fff',
                        }}
                      >
                        닫기
                      </button>
                    </ButtonWrapper>
                  ) : (
                    version &&
                    version.versionId === item.versionId && (
                      <ButtonWrapper style={{ margin: '5px 0' }}>
                        <button
                          onClick={() => {
                            setEdit(item)
                          }}
                          style={{
                            backgroundColor: '#007ffb',
                          }}
                        >
                          수정
                        </button>
                        <button
                          onClick={() => {
                            setPopup('copy')
                          }}
                          style={{
                            backgroundColor: '#ff4500',
                          }}
                        >
                          복사
                        </button>
                        <button
                          onClick={() => {
                            deleteVersion()
                          }}
                          style={{
                            backgroundColor: '#dc143c',
                          }}
                        >
                          삭제
                        </button>
                      </ButtonWrapper>
                    )
                  )}
                </VersionItem>
              ))}
            {create ? (
              <VersionInputWrapper>
                <div>버전</div>
                <input
                  type={'text'}
                  value={name}
                  onChange={e => {
                    setName(e.target.value)
                  }}
                />
                <ButtonWrapper>
                  <button
                    onClick={async () => {
                      await createVersion()
                    }}
                    style={{ backgroundColor: '#007ffb' }}
                  >
                    생성
                  </button>
                  <button
                    onClick={() => {
                      setCreate(false)
                    }}
                    style={{
                      backgroundColor: '#dc143c',
                      color: '#fff',
                    }}
                  >
                    닫기
                  </button>
                </ButtonWrapper>
              </VersionInputWrapper>
            ) : (
              <ButtonWrapper>
                <button
                  style={{ backgroundColor: '#007bff' }}
                  onClick={() => {
                    setCreate(true)
                  }}
                >
                  버전 추가
                </button>
              </ButtonWrapper>
            )}
          </VersionWrapper>
          <VersionWrapper>
            <Title>이전 큐레이션</Title>
            {prevV.length > 0 &&
              prevV.map((item, index) => (
                <VersionItem
                  key={index}
                  active={version && version.versionId === item.versionId}
                >
                  {edit && edit.versionId === item.versionId ? (
                    <input
                      type="text"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  ) : (
                    <div
                      onClick={() => {
                        getVersion(item.versionId, false)
                      }}
                    >
                      - {item.version}
                    </div>
                  )}
                  {edit && edit.versionId === item.versionId ? (
                    <ButtonWrapper style={{ margin: '5px 0' }}>
                      <button
                        onClick={async () => {
                          await editVersion()
                        }}
                        style={{ backgroundColor: '#007ffb' }}
                      >
                        저장
                      </button>
                      <button
                        onClick={() => {
                          setEdit(null)
                        }}
                        style={{
                          backgroundColor: '#dc143c',
                          color: '#fff',
                        }}
                      >
                        닫기
                      </button>
                    </ButtonWrapper>
                  ) : (
                    version &&
                    version.versionId === item.versionId && (
                      <ButtonWrapper style={{ margin: '5px 0' }}>
                        <button
                          onClick={() => {
                            setEdit(item)
                          }}
                          style={{
                            backgroundColor: '#007ffb',
                          }}
                        >
                          수정
                        </button>
                        <button
                          onClick={() => {
                            setPopup('copy')
                          }}
                          style={{
                            backgroundColor: '#ff4500',
                          }}
                        >
                          복사
                        </button>
                        <button
                          onClick={() => {
                            deleteVersion()
                          }}
                          style={{
                            backgroundColor: '#dc143c',
                          }}
                        >
                          삭제
                        </button>
                      </ButtonWrapper>
                    )
                  )}
                </VersionItem>
              ))}
          </VersionWrapper>
        </>
      )}
    </Wrapper>
  )
}

export default Version

const Wrapper = styled.div`
  width: 240px;
  height: calc(100vh - 150px);
  background-color: #fff;
  border: 1px solid #dbdbdb;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
`

const VersionWrapper = styled.div`
  width: 100%;
  background-color: #ecf0f5;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
`

const VersionItem = styled.div`
  font-size: 14px;
  font-weight: ${props => props.active && 'bold'};
  cursor: pointer;
`

const VersionInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: bold;

  input[type='text'] {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 10px;
  column-gap: 5px;

  button {
    width: 100%;
    height: 30px;
    padding: 0 5px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 5px;

    &:hover {
      opacity: 0.8;
    }
  }
`
