import React, { useState, useEffect } from 'react'
import PromptEdit from '../../Components/Claude/PromptEdit'
import axios from 'axios'
import {
  STIPOP_API_HOST,
  STIPOP_API_KEY,
} from '../../_Components/Common/common'

const ClaudePrompt = ({ match, location }) => {
  const [model, setModel] = useState('sonnet')
  const [devData, setDevData] = useState({})
  const [data, setData] = useState({})

  const getClaudePrompt = async () => {
    await axios
      .get(`${STIPOP_API_HOST}/v4/message/claude/prompt/${model}?isdev=Y`, {
        headers: {
          apikey: STIPOP_API_KEY,
        },
      })
      .then(({ data }) => {
        console.log(data)
        setDevData(data.body)
      })

    await axios
      .get(`${STIPOP_API_HOST}/v4/message/claude/prompt/${model}`, {
        headers: {
          apikey: STIPOP_API_KEY,
        },
      })
      .then(({ data }) => {
        setData(data.body)
      })
  }

  const updateClaudePrompt = (id, prompt) => {
    axios
      .put(
        `${STIPOP_API_HOST}/v4/message/claude/prompt/${id}`,
        {
          prompt,
        },
        {
          headers: {
            apikey: STIPOP_API_KEY,
          },
        }
      )
      .then(() => {
        getClaudePrompt(model)
      })
  }

  const updateDevClaudePrompt = (id, prompt) => {
    axios
      .put(
        `${STIPOP_API_HOST}/v4/message/claude/prompt/${id}?isdev=Y`,
        {
          prompt,
        },
        {
          headers: {
            apikey: STIPOP_API_KEY,
          },
        }
      )
      .then(() => {
        getClaudePrompt(model)
      })
  }

  useEffect(() => {
    getClaudePrompt(model)
  }, [model])

  return (
    <React.Fragment>
      <PromptEdit
        model={model}
        setModel={setModel}
        data={data}
        setData={setData}
        devData={devData}
        setDevData={setDevData}
        updateClaudePrompt={updateClaudePrompt}
        updateDevClaudePrompt={updateDevClaudePrompt}
      />
    </React.Fragment>
  )
}

export default ClaudePrompt
