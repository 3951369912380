import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import CollectionNameDropdown from './CollectionNameDropdown'
import Dropdown from '../../Components/NewApp/Dropdown'

const EditCurationPackage = props => {
  const {
    collectionData,
    collectionId,
    setEdit,
    getCollection,
    getStickers,
    page,
    version,
    lang,
    platform,
  } = props
  const [data, setData] = useState([])
  const [title, setTitle] = useState('')
  const [checked, setChecked] = useState([])
  const [drag, setDrag] = useState(false)
  const [newData, setNewData] = useState({
    collectionName: '',
    pageName: page,
    status: version.status,
    title: '',
    type: '',
    version: version.id,
  })
  const [collectionName, setCollectionName] = useState('')
  const checkRef = useRef([])
  const curationRef = useRef()
  const [image, setImage] = useState([])
  const [isScroll, setIsScroll] = useState('')
  const [posY, setPosY] = useState(0)
  const [filter, setFilter] = useState('')
  const filterList = ['all', 'female', 'male']

  useEffect(() => {
    if (collectionId !== 'new') {
      setData(
        collectionData.filter(item => item.collectionId === collectionId)[0]
          .collection
      )
      setTitle(
        collectionData.filter(item => item.collectionId === collectionId)[0]
          .title
      )
      setFilter(
        collectionData.filter(item => item.collectionId === collectionId)[0]
          .filter
      )
      setCollectionName(
        collectionData.filter(item => item.collectionId === collectionId)[0]
          .collectionName
      )
    } else {
      setData([])
    }
  }, [collectionId])

  useEffect(() => {
    // console.log(newData)
    if (collectionId === 'new') {
      setCollectionName(newData.collectionName)
    }
  }, [newData])

  useEffect(() => {
    if (collectionId === 'new') {
      if (!collectionName) {
        setData([])
      } else {
        if (collectionName === 'top table') {
          setData([])
        } else if (
          collectionName === 'trio card' ||
          collectionName === 'md card'
        ) {
          setData([
            {
              seq: uuidv4(),
              packageId: '',
              stickerId: '',
              bgColor: '',
              userId: '',
              userName: '',
              contents: '',
            },
            {
              seq: uuidv4(),
              packageId: '',
              stickerId: '',
              bgColor: '',
              userId: '',
              userName: '',
              contents: '',
            },
            {
              seq: uuidv4(),
              packageId: '',
              stickerId: '',
              bgColor: '',
              userId: '',
              userName: '',
              contents: '',
            },
          ])
        } else if (collectionName === 'ai card') {
          setData([
            {
              seq: uuidv4(),
              assistantId: '',
            },
          ])
        } else {
          setData([
            {
              seq: uuidv4(),
              packageId: '',
              stickerId: '',
              bgColor: '',
              userId: '',
              userName: '',
              contents: '',
            },
          ])
        }
      }
    }
  }, [collectionName])

  // useEffect(() => {
  //   console.log(data)
  // }, [data])

  const handleCheck = e => {
    // console.log(e.target.value)
    if (e.target.checked) {
      setChecked(checked.concat(e.target.value.split(',')))
    } else {
      // console.log(e.target.value.split(','))
      setChecked(
        checked.filter(seq => e.target.value.split(',').indexOf(seq) < 0)
      )
    }
  }

  // drag and drop reorder
  const dragDataInit = {
    target: null,
    index: -1,
    move_up: [],
    move_down: [],
    updateList: [],
  }

  const [dragData, setDragData] = useState(dragDataInit)

  const _dragStart = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    setDragData({
      ...dragData,
      target: e.currentTarget,
      index: Number(e.currentTarget.dataset.index),
      updateList: [...data],
    })

    e.dataTransfer.setData('text/html', '')
    e.dataTransfer.effectAllowed = 'move'
  }

  const _dragEnter = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    const _dragged = Number(dragData.target.dataset.index)
    const _target = Number(e.currentTarget.dataset.index)
    let move_down = [...dragData.move_down]
    let move_up = [...dragData.move_up]

    if (_dragged > _target) {
      if (move_down.includes(_target)) {
        move_down = move_down.slice(0, move_down.indexOf(_target))
      } else {
        move_down = Array(_dragged - _target)
          .fill()
          .map((v, i) => i + _target)
          .reverse()
      }
      move_up = []
    } else if (_dragged < _target) {
      if (move_up.includes(_target)) {
        move_up = move_up.slice(0, move_up.indexOf(_target))
      } else {
        move_up = Array(_target - _dragged)
          .fill()
          .map((v, i) => _target - i)
          .reverse()
      }
      move_down = []
    } else {
      move_down = []
      move_up = []
    }

    setDragData({
      ...dragData,
      // updateList: updateData,
      index: _target,
      move_up,
      move_down,
    })
  }

  const _dragLeave = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    if (e.currentTarget === dragData.target) {
      e.currentTarget.style.visibility = 'hidden'
    }
  }

  const _dragEnd = e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    let updateData = [...dragData.updateList]

    const changeOrder = (i, t) => {
      if (collectionName === 'trio card' || collectionName === 'md card') {
        const target = updateData.splice(3 * i, 3)
        updateData.splice(3 * t, 0, target[0])
        updateData.splice(3 * t + 1, 0, target[1])
        updateData.splice(3 * t + 2, 0, target[2])
      } else {
        const target = updateData.splice(i, 1)[0]
        updateData.splice(t, 0, target)
        // updateData[i] = updateData.splice(t, 1, updateData[i])[0]
      }
    }

    const _dragged = Number(e.currentTarget.dataset.index)
    let _target = Number(dragData.index)

    if (dragData.move_up.length > 0) {
      _target = dragData.move_up[dragData.move_up.length - 1]
    } else if (dragData.move_down.length > 0) {
      _target = dragData.move_down[dragData.move_down.length - 1]
    } else {
      _target = _dragged
    }

    changeOrder(_dragged, _target)

    setDrag(false)
    setData(updateData)
    setDragData({
      ...dragData,
      move_down: [],
      move_up: [],
      updateList: [],
    })

    e.currentTarget.style.visibility = 'visible'
    e.dataTransfer.dropEffect = 'move'
  }

  const _dragCapture = async e => {
    if (!drag) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    const posH = e.target.getBoundingClientRect().height
    const bottom = curationRef.current.getBoundingClientRect().bottom
    const top = curationRef.current.getBoundingClientRect().top

    setPosY(posH)

    if (e.clientY && e.clientY > top && e.clientY < bottom) {
      if (bottom - e.clientY < 100) {
        setIsScroll('down')
      } else if (e.clientY - top < 100) {
        setIsScroll('up')
      } else {
        setIsScroll('')
      }
    }
  }

  useEffect(() => {
    const scrollHeight = curationRef.current.scrollTop
    const posH = curationRef.current.getBoundingClientRect().height

    if (isScroll === 'down') {
      curationRef.current.scrollTo({
        top: scrollHeight + posH - posY * 2,
        behavior: 'smooth',
      })
    }

    if (isScroll === 'up') {
      curationRef.current.scrollTo({
        top: scrollHeight - posH + posY * 2,
        behavior: 'smooth',
      })
    }
  }, [isScroll])

  //delete
  const clickDelete = () => {
    if (checked.length > 0) {
      setData(data.filter(item => !checked.includes(String(item.seq))))
    }
    if (checkRef.current) {
      for (let i = 0; i < checkRef.current.length; i++) {
        if (checkRef.current[i] && checkRef.current[i].checked) {
          checkRef.current[i].checked = false
        }
      }
    }
    setChecked([])
  }

  //generate unique id
  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  }
  //add
  const clickAdd = () => {
    if (collectionId === 'new' && !collectionName) {
      alert('collection name을 선택해주세요.')
    } else if (collectionName === 'top table') {
      alert('top table은 항목을 추가할 수 없습니다.')
    } else if (collectionName === 'trio card') {
      clickTrioAdd()
    } else {
      const temp = JSON.parse(JSON.stringify(data))
      temp.unshift({
        seq: uuidv4(),
        packageId: '',
        stickerId: '',
        bgColor: '',
        userId: '',
        userName: '',
        contents: '',
      })
      setData(temp)
    }
  }

  //trio add
  const clickTrioAdd = () => {
    if (collectionName === 'trio card' || collectionName === 'md card') {
      const temp = [...data]
      temp.unshift(
        {
          seq: uuidv4(),
          packageId: '',
          stickerId: '',
          bgColor: '',
          userId: '',
          userName: '',
          contents: '',
        },
        {
          seq: uuidv4(),
          packageId: '',
          stickerId: '',
          bgColor: '',
          userId: '',
          userName: '',
          contents: '',
        },
        {
          seq: uuidv4(),
          packageId: '',
          stickerId: '',
          bgColor: '',
          userId: '',
          userName: '',
          contents: '',
        }
      )
      setData(temp)
    }
  }

  //collection name select
  const selectCollection = async e => {
    await setNewData({
      ...newData,
      collectionName: e,
      type:
        ['main', 'spotlight', 'new main'].indexOf(e) > -1
          ? 'main'
          : ['card', 'mini cards', 'center card', 'ai card'].indexOf(e) > -1
          ? 'card'
          : ['trio card', 'md card'].indexOf(e) > -1
          ? 'trio'
          : e === 'artist'
          ? 'artist'
          : e === 'top table'
          ? 'table'
          : e === 'categories'
          ? 'category'
          : e === 'free' && 'free',
    })
  }

  //save
  const clickSave = async id => {
    if (!collectionName) {
      alert('배너 종류를 선택해 주세요.')
      return
    }
    const collection =
      collectionId === 'new'
        ? newData
        : collectionData.filter(item => item.collectionId === id)[0]

    let formData = new FormData()
    let imgId = ''

    await formData.append('page', page)
    await formData.append('type', collection.type)
    await formData.append('collectionName', collection.collectionName)
    await formData.append(
      'title',
      collectionId === 'new' ? newData.title : title
    )
    await formData.append('packageInput', JSON.stringify(data))
    await formData.append('version', version.id)

    if (collectionId !== 'new') {
      await formData.append('collectionId', collectionId)
      await formData.append('status', collection.status)
    } else {
      await formData.append('status', version.status)
    }
    if (page === 'nsfw') {
      await formData.append('filter', filter)
    }

    for (const tmp of data) {
      if (image.filter(item => item.seq === tmp.seq).length > 0) {
        await formData.append(
          'imgs',
          image.filter(item => item.seq === tmp.seq)[0].file[0]
        )

        if (collectionId === 'new') {
          imgId += 'new,'
        } else {
          if (typeof tmp.seq !== 'string') {
            imgId += `${tmp.seq},`
          } else {
            imgId += 'new,'
          }
        }
      } else {
        imgId += 'null,'
      }
    }

    await formData.append('updateImgId', imgId.substring(0, imgId.length - 1))
    await formData.append('platform', platform)

    await fetch('/new/webAdmin/v2/collection', {
      headers: {
        token: sessionStorage.getItem('token'),
      },
      method: collectionId === 'new' ? 'POST' : 'PUT',
      body: formData,
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === 'fail' && responseJson.code === '1111')
          props.history.push('/')
        setEdit('')
        getStickers(page, lang, version)
        getCollection(page, lang, version)
      })
  }

  //sticker img reload
  const stickerPreview = item => {
    axios
      .get(`/new/webAdmin/v2/collection/sticker/${item.stickerId}`, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(res => {
        const result = res.data.result
        if (result) {
          setData(
            data.map(d =>
              d.seq === item.seq ? { ...d, stickerImg: result.s_img } : d
            )
          )
        }
      })
  }

  const assistantPreview = item => {
    axios
      .get(`/new/webAdmin/v2/collection/assistant/${item.assistantId}`, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(res => {
        const result = res.data.result
        if (result) {
          setData(
            data.map(d =>
              d.seq === item.seq
                ? {
                    ...d,
                    profileImg:
                      result.profileImg ||
                      'https://img.stipop.io/image/stipop/icon/chat/chat-create-profile-icon.png',
                  }
                : d
            )
          )
        }
      })
  }

  //artist info reload
  const artistPreview = item => {
    axios
      .get(`/new/webAdmin/v2/collection/artist/${item.userId}`, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(res => {
        const result = res.data.result
        // console.log(result)
        if (result) {
          setData(
            data.map(d =>
              d.seq === item.seq
                ? { ...d, artistImg: result.u_img, userName: result.u_name }
                : d
            )
          )
        }
      })
  }

  //main collection
  const renderMain = (item, index) => {
    return (
      <>
        <CheckBox>
          <input
            ref={el => (checkRef.current[index] = el)}
            type="checkbox"
            value={item.seq}
            onChange={handleCheck}
          />
        </CheckBox>
        <Index>#{index + 1}</Index>
        <TextInput>
          <span>Package ID</span>
          <input
            type="text"
            value={item.packageId ? item.packageId : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq ? { ...d, packageId: e.target.value } : d
                )
              )
            }}
          />
        </TextInput>
        {(collectionName === 'main' || collectionName === 'new main') && (
          <TextInput>
            <span>배경코드</span>
            <input
              type="text"
              value={item.bgColor ? item.bgColor : ''}
              onChange={e => {
                setData(
                  data.map(d =>
                    d.seq === item.seq ? { ...d, bgColor: e.target.value } : d
                  )
                )
              }}
            />
          </TextInput>
        )}
        <FileInput
          style={{
            width: collectionName === 'spotlight' && 'calc(65% - 10px)',
            minWidth: collectionName === 'spotlight' && '425.5px',
          }}
        >
          <Label>
            <label className="button" htmlFor={item.seq}>
              파일 선택
            </label>
            {image.filter(i => i.seq === item.seq).length > 0 && (
              <div className="file-name">
                {image.filter(i => i.seq === item.seq)[0].file[0].name}
              </div>
            )}
          </Label>
          <input
            id={item.seq}
            type="file"
            onChange={e => {
              if (e.target.files && e.target.files[0]) {
                if (image.length > 0) {
                  if (image.filter(i => i.seq === item.seq).length > 0) {
                    setImage(
                      image.map(i =>
                        i.seq === item.seq
                          ? {
                              ...i,
                              img: URL.createObjectURL(e.target.files[0]),
                              file: e.target.files,
                            }
                          : i
                      )
                    )
                  } else {
                    setImage(
                      image.concat({
                        seq: item.seq,
                        img: URL.createObjectURL(e.target.files[0]),
                        file: e.target.files,
                      })
                    )
                  }
                } else {
                  setImage(
                    image.concat({
                      seq: item.seq,
                      img: URL.createObjectURL(e.target.files[0]),
                      file: e.target.files,
                    })
                  )
                }
              }
            }}
            multiple={false}
          />
          <Preview>
            <img
              src={
                image.length > 0
                  ? image.filter(i => i.seq === item.seq).length > 0
                    ? image.filter(i => i.seq === item.seq)[0].img
                    : item.uploadImg
                    ? item.uploadImg
                    : ''
                  : item.uploadImg
                  ? item.uploadImg
                  : ''
              }
              alt=""
            />
          </Preview>
        </FileInput>
        <Order>
          <img
            id="drag"
            src="https://img.stipop.io/image/account/hamburger_menu.png"
            alt=""
            draggable={false}
            onMouseDown={() => setDrag(true)}
          />
        </Order>
      </>
    )
  }

  //card collection
  const renderCard = (item, index) => {
    return (
      <>
        <CheckBox>
          <input
            ref={el => (checkRef.current[index] = el)}
            type="checkbox"
            value={item.seq}
            onChange={handleCheck}
          />
        </CheckBox>
        <Index>#{index + 1}</Index>
        <TextInput>
          <span>Package ID</span>
          <input
            type="text"
            value={item.packageId ? item.packageId : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq ? { ...d, packageId: e.target.value } : d
                )
              )
            }}
          />
        </TextInput>
        <TextInput>
          <span>Sticker ID</span>
          <input
            type="text"
            value={item.stickerId ? item.stickerId : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq ? { ...d, stickerId: e.target.value } : d
                )
              )
            }}
            onBlur={e => {
              if (e.target.value) {
                stickerPreview(item)
              }
            }}
          />
        </TextInput>
        {(collectionName === 'trio card' ||
          collectionName === 'mini cards' ||
          collectionName === 'categories') && (
          <TextInput>
            <span>배경코드</span>
            <input
              type="text"
              value={item.bgColor ? item.bgColor : ''}
              onChange={e => {
                setData(
                  data.map(d =>
                    d.seq === item.seq ? { ...d, bgColor: e.target.value } : d
                  )
                )
              }}
            />
          </TextInput>
        )}
        <Preview
          bgColor={item.bgColor}
          style={{
            width:
              collectionName === 'card' || collectionName === 'free'
                ? 'calc(50% - 10px)'
                : 'calc(35% - 10px)',
            minWidth:
              collectionName === 'card' || collectionName === 'free'
                ? '325px'
                : '224.5px',
          }}
        >
          <img
            id="preview"
            src={item.stickerImg ? item.stickerImg : ''}
            alt=""
          />
        </Preview>
        <Order>
          <img
            id="drag"
            src="https://img.stipop.io/image/account/hamburger_menu.png"
            alt=""
            draggable={false}
            onMouseDown={() => setDrag(true)}
          />
        </Order>
      </>
    )
  }

  const renderAiCard = (item, index) => {
    return (
      <>
        <CheckBox>
          <input
            ref={el => (checkRef.current[index] = el)}
            type="checkbox"
            value={item.seq}
            onChange={handleCheck}
          />
        </CheckBox>
        <Index>#{index + 1}</Index>
        <TextInput>
          <span>Assistant ID</span>
          <input
            type="text"
            value={item.assistantId ? item.assistantId : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq ? { ...d, assistantId: e.target.value } : d
                )
              )
            }}
            onBlur={e => {
              if (e.target.value) {
                assistantPreview(item)
              }
            }}
          />
        </TextInput>
        <Preview
          style={{
            width:
              collectionName === 'card' || collectionName === 'free'
                ? 'calc(50% - 10px)'
                : 'calc(35% - 10px)',
            minWidth:
              collectionName === 'card' || collectionName === 'free'
                ? '325px'
                : '224.5px',
          }}
        >
          <img
            id="preview"
            src={item.profileImg ? item.profileImg : ''}
            alt=""
          />
        </Preview>
        <Order>
          <img
            id="drag"
            src="https://img.stipop.io/image/account/hamburger_menu.png"
            alt=""
            draggable={false}
            onMouseDown={() => setDrag(true)}
          />
        </Order>
      </>
    )
  }

  //render artist
  const renderArtist = (item, index) => {
    return (
      <>
        <CheckBox>
          <input
            ref={el => (checkRef.current[index] = el)}
            type="checkbox"
            value={item.seq}
            onChange={handleCheck}
          />
        </CheckBox>
        <Index>#{index + 1}</Index>
        <TextInput>
          <span>User ID</span>
          <input
            type="text"
            value={item.userId ? item.userId : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq ? { ...d, userId: e.target.value } : d
                )
              )
            }}
            onBlur={e => {
              if (e.target.value) {
                artistPreview(item)
              }
            }}
          />
        </TextInput>
        <TextInput>
          <span>User Name</span>
          <input
            type="text"
            value={item.userName ? item.userName : ''}
            onBlur={e => {
              if (e.target.value) {
                artistPreview(item)
              }
            }}
            disabled={true}
          />
        </TextInput>
        <Preview>
          <img
            id="artist"
            src={
              item.artistImg
                ? item.artistImg
                : 'https://img.stipop.io/image/studio/stipop-dummy-avatar.png'
            }
            alt=""
          />
        </Preview>
        <Order>
          <img
            id="drag"
            src="https://img.stipop.io/image/account/hamburger_menu.png"
            alt=""
            draggable={false}
            onMouseDown={() => setDrag(true)}
          />
        </Order>
      </>
    )
  }

  const renderTrio = () => {
    const division = (arr, n) => {
      const len = arr.length
      const divide = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0)
      const newArr = []

      for (let i = 0; i < divide; i++) {
        newArr.push(arr.slice(i * 3, i * 3 + 3))
      }

      return newArr
    }

    return (
      <>
        {division(data, 3).map((trio, i) => {
          let default_class = ''
          dragData.move_down.includes(i) && (default_class = 'move_down')
          dragData.move_up.includes(i) && (default_class = 'move_up')
          return (
            <TrioWrapper
              key={i}
              data={trio}
              data-index={i}
              draggable={drag}
              onDragStart={_dragStart}
              onDragEnter={_dragEnter}
              onDragLeave={_dragLeave}
              onDragEnd={_dragEnd}
              onDragCapture={_dragCapture}
              className={default_class}
            >
              <CheckBox
                style={{
                  width: '5%',
                  maxWidth: '33px',
                  justifyContent: 'start',
                }}
              >
                <input
                  ref={el => (checkRef.current[i] = el)}
                  type="checkbox"
                  value={trio.map(t => t.seq)}
                  onChange={handleCheck}
                />
              </CheckBox>
              <Index style={{ width: '5%', minWidth: '33px' }}>#{i + 1}</Index>
              <TextInput style={{ width: '15%', minWidth: '99px' }}>
                <span>Package ID</span>
                <input
                  type="text"
                  value={trio[0].packageId ? trio[0].packageId : ''}
                  onChange={e => {
                    setData(
                      data.map(d =>
                        trio.map(t => t.seq).indexOf(d.seq) > -1
                          ? { ...d, packageId: e.target.value }
                          : d
                      )
                    )
                  }}
                />
              </TextInput>
              <div
                style={{
                  width: '15%',
                  minWidth: '99px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginTop: '10px',
                }}
              >
                {trio &&
                  trio.map((sticker, index) => {
                    return (
                      <TrioItemWrapper key={index}>
                        {renderTrioItem(sticker, index, i)}
                      </TrioItemWrapper>
                    )
                  })}
              </div>
              {collectionName === 'trio card' ? (
                <TextInput style={{ width: '15%', minWidth: '99px' }}>
                  <span>배경코드</span>
                  <input
                    type="text"
                    value={trio[0].bgColor ? trio[0].bgColor : ''}
                    onChange={e => {
                      setData(
                        data.map(d =>
                          trio.map(t => t.seq).indexOf(d.seq) > -1
                            ? { ...d, bgColor: e.target.value }
                            : d
                        )
                      )
                    }}
                  />
                </TextInput>
              ) : (
                collectionName === 'md card' && (
                  <TextInput style={{ width: '20%', minWidth: '132px' }}>
                    <span>Contents</span>
                    <textarea
                      type="text"
                      value={trio[0].contents ? trio[0].contents : ''}
                      onChange={e => {
                        setData(
                          data.map(d =>
                            trio.map(t => t.seq).indexOf(d.seq) > -1
                              ? { ...d, contents: e.target.value }
                              : d
                          )
                        )
                      }}
                    />
                  </TextInput>
                )
              )}
              <div
                style={{
                  width: collectionName === 'trio card' ? '35%' : '30%',
                  minWidth: collectionName === 'trio card' ? '231px' : '198px',
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <StickerWrapper
                  type={collectionName}
                  bgColor={
                    trio.filter((s, i) => s.bgColor !== null)[0]
                      ? trio.filter((s, i) => s.bgColor !== null)[0].bgColor
                      : '#fff'
                  }
                >
                  {trio &&
                    trio.map((sticker, index) =>
                      index % 3 === 0 ? (
                        <StickerMain key={index} type={collectionName}>
                          {sticker.stickerImg ? (
                            <img src={sticker.stickerImg} alt="" />
                          ) : (
                            ''
                          )}
                        </StickerMain>
                      ) : index % 3 === 1 ? (
                        <StickerItem key={index} n={index % 3}>
                          {sticker.stickerImg ? (
                            <img src={sticker.stickerImg} alt="" />
                          ) : (
                            ''
                          )}
                        </StickerItem>
                      ) : (
                        <StickerItem
                          key={index}
                          n={index % 3}
                          style={{
                            bottom:
                              collectionName === 'trio card' ? '25px' : '45px',
                          }}
                        >
                          {sticker.stickerImg ? (
                            <img src={sticker.stickerImg} alt="" />
                          ) : (
                            ''
                          )}
                        </StickerItem>
                      )
                    )}
                  {collectionName === 'md card' && (
                    <Contents>
                      <img
                        src="https://img.stipop.io/image/b2b/description.png"
                        alt=""
                      />
                      <span>
                        {trio.filter((s, i) => s.contents !== null)[0]
                          ? trio
                              .filter((s, i) => s.contents !== null)[0]
                              .contents.replaceAll('<br>', '\r\n')
                          : ''}
                      </span>
                    </Contents>
                  )}
                </StickerWrapper>
              </div>
              <Order style={{ justifyContent: 'end', minWidth: '66px' }}>
                <img
                  id="drag"
                  src="https://img.stipop.io/image/account/hamburger_menu.png"
                  alt=""
                  draggable={false}
                  onMouseDown={() => setDrag(true)}
                  style={{ width: '35px', height: '25px' }}
                />
              </Order>
            </TrioWrapper>
          )
        })}
      </>
    )
  }

  const renderTrioItem = (item, index, i) => {
    return (
      <>
        <TextInput style={{ width: '100%' }}>
          <span>Sticker ID</span>
          <input
            type="text"
            value={item.stickerId ? item.stickerId : ''}
            onChange={e => {
              setData(
                data.map(d =>
                  d.seq === item.seq ? { ...d, stickerId: e.target.value } : d
                )
              )
            }}
            onBlur={e => {
              if (e.target.value) {
                stickerPreview(item)
              }
            }}
          />
        </TextInput>
      </>
    )
  }

  return (
    <Wrapper>
      <ButtonWrapper>
        <button
          onClick={() => setEdit('')}
          style={{
            backgroundColor: '#818181',
          }}
        >
          목록으로
        </button>
        <button
          onClick={() => clickDelete()}
          style={{ backgroundColor: '#dc143c' }}
        >
          선택 삭제
        </button>
        <button
          onClick={() => {
            if (
              collectionName === 'trio card' ||
              collectionName === 'md card'
            ) {
              clickTrioAdd()
            } else {
              clickAdd()
            }
          }}
          style={{ backgroundColor: '#ff4500' }}
        >
          새 구간 추가
        </button>
        <button
          onClick={() => clickSave(collectionId)}
          style={{ backgroundColor: '#007ffb' }}
        >
          배너 저장
        </button>
      </ButtonWrapper>
      <EditCurationWrapper>
        <TitleWrapper>
          {collectionId === 'new' && (
            <div style={{ marginBottom: '10px' }}>
              <span>Collection Name</span>
              <CollectionNameDropdown
                page={page}
                name={newData.collectionName}
                setName={e => selectCollection(e)}
              />
            </div>
          )}
          {collectionName === 'main' ||
          collectionName === 'new main' ||
          collectionName === 'top table' ? (
            <span style={{ marginBottom: '15px' }}>
              {collectionName.charAt(0).toUpperCase() + collectionName.slice(1)}
            </span>
          ) : (
            <>
              <span>
                Title
                {/* {collectionId !== 'new' && `(${collectionName})`} */}
              </span>
              <TitleInput
                value={collectionId === 'new' ? newData.title : title}
                onChange={e => {
                  if (collectionId === 'new') {
                    setNewData({
                      ...newData,
                      title: e.target.value,
                    })
                  } else {
                    setTitle(e.target.value)
                  }
                }}
              />
            </>
          )}
        </TitleWrapper>
        {page === 'nsfw' && (
          <TitleWrapper>
            <span>Filter</span>
            <div style={{ marginBottom: '10px' }}>
              <Dropdown
                list={filterList}
                onChange={e => setFilter(e.target.value)}
                value={filter}
                type={'page'}
              />
            </div>
          </TitleWrapper>
        )}
        <CurationWrapper ref={curationRef}>
          {drag &&
            (isScroll === 'up' ? (
              <StickyTop />
            ) : (
              isScroll === 'down' && <StickyBottom />
            ))}
          {data &&
          (collectionName === 'trio card' || collectionName === 'md card')
            ? renderTrio()
            : data.map((item, index) => {
                let default_class = ''
                dragData.move_down.includes(index) &&
                  (default_class = 'move_down')
                dragData.move_up.includes(index) && (default_class = 'move_up')
                return (
                  <CurationItem
                    key={index}
                    data={item}
                    data-index={index}
                    draggable={drag}
                    onDragStart={_dragStart}
                    onDragEnter={_dragEnter}
                    onDragLeave={_dragLeave}
                    onDragEnd={_dragEnd}
                    onDragCapture={_dragCapture}
                    className={default_class}
                  >
                    {page === 'home'
                      ? collectionName === 'main' ||
                        collectionName === 'spotlight'
                        ? renderMain(item, index)
                        : collectionName === 'card' ||
                          collectionName === 'mini cards' ||
                          collectionName === 'free' ||
                          collectionName === 'center card'
                        ? renderCard(item, index)
                        : renderArtist(item, index)
                      : page === 'new'
                      ? renderMain(item, index)
                      : page === 'search'
                      ? renderCard(item, index)
                      : (page === 'ai' || page === 'nsfw') &&
                        renderAiCard(item, index)}
                  </CurationItem>
                )
              })}
        </CurationWrapper>
      </EditCurationWrapper>
    </Wrapper>
  )
}

export default EditCurationPackage

const Wrapper = styled.div`
  /* max-width: calc(100vw - 905px); */
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`

const EditCurationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  height: calc(100% - 60px);
  overflow: hidden;
`

const CurationWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

const StickyTop = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: transparent;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 99;

  -webkit-box-shadow: 0 -50px 20px 50px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 -50px 20px 50px rgba(0, 0, 0, 0.8);
  box-shadow: 0 -50px 20px 50px rgba(0, 0, 0, 0.8);
`

const StickyBottom = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 100%;
  width: 100%;
  height: 0;
  z-index: 99;

  -webkit-box-shadow: 0 50px 20px 50px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 50px 20px 50px rgba(0, 0, 0, 0.8);
  box-shadow: 0 50px 20px 50px rgba(0, 0, 0, 0.8);
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
  position: absolute;
  top: 0;

  button {
    height: 35px;
    border: none;
    border-radius: 5px;
    padding: 0 10px;
    margin-left: 10px;
    color: #fff;

    &:hover {
      opacity: 0.8;
      font-weight: bold;
    }
  }
`

const CurationItem = styled.div`
  width: 100%;
  min-width: 700px;
  height: 90px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 15px;
  position: relative;
  opacity: 0.999;

  #drag {
    width: 25px;
    height: 20px;
    cursor: pointer;
  }

  &.move_up {
    transform: translateY(-100px);
    z-index: 1;
  }
  &.move_down {
    transform: translateY(100px);
    z-index: 1;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* min-width: 700px; */

  span {
    font-weight: bold;
    font-size: 20px;
  }
`

const TitleInput = styled.input`
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
`

const CheckBox = styled.div`
  width: 5%;
  min-width: 33.5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }
`

const Index = styled.div`
  width: 5%;
  min-width: 33.5px;
  height: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`

const TextInput = styled.div`
  width: 15%;
  min-width: 100.5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  span {
    width: 90%;
    font-size: 12px;
    font-weight: bold;
  }

  input[type='text'] {
    width: 90%;
    height: 30px;
  }

  textarea {
    width: 95%;
    height: 50%;
  }
`

const FileInput = styled.div`
  width: calc(50% - 10px);
  min-width: 325px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    display: none;
  }
`

const Label = styled.div`
  width: 30%;
  min-width: 97.5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0;

  & .button {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #efefef;
    border: 1px solid #000;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .file-name {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
  }
`

const Preview = styled.div`
  width: 70%;
  min-width: 227.5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    max-width: 100%;
    background-color: ${props => props.bgColor};
  }

  #artist {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }
`

const Order = styled.div`
  width: 10%;
  min-width: 67px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`

const TrioWrapper = styled.div`
  width: 100%;
  min-width: 700px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 15px;
  position: relative;
  opacity: 0.999;

  #drag {
    width: 25px;
    height: 20px;
    cursor: pointer;
  }

  &.move_up {
    transform: translateY(-207px);
    z-index: 1;
  }
  &.move_down {
    transform: translateY(207px);
    z-index: 1;
  }
`

const TrioItemWrapper = styled.div`
  width: 100%;
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
`

const StickerWrapper = styled.div`
  width: ${props => (props.type === 'trio card' ? '221.4px' : '194.4px')};
  height: ${props => (props.type === 'trio card' ? '162px' : '153.9px')};
  background-color: ${props => props.bgColor};
  border: ${props => props.type === 'md card' && '1px solid #000'};
  border-radius: 36px;
  padding: ${props =>
    props.type === 'trio card' ? '25px 19px' : '12px 13px 0'};
  position: relative;
`

const StickerMain = styled.div`
  width: ${props => (props.type === 'trio card' ? '112px' : '96.9px')};
  height: ${props => (props.type === 'trio card' ? '112px' : '96.9px')};
  position: absolute;
  top: ${props => (props.type === 'trio card' ? '25px' : '12px')};
  left: ${props => (props.left === 'trio card' ? '19px' : '15px')};

  img {
    width: 100%;
    height: 100%;
  }
`

const StickerItem = styled.div`
  width: ${props => (props.type === 'trio card' ? '56px' : '48.45px')};
  height: ${props => (props.type === 'trio card' ? '56px' : '48.45px')};
  position: absolute;
  right: ${props => (props.type === 'trio card' ? '19px' : '15px')};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 90%;
    height: 90%;
  }
`

const Contents = styled.div`
  width: 168.4px;
  height: 43px;
  display: flex;
  align-items: center;
  color: #000;
  font-size: 10px;
  font-family: Poppins;
  position: absolute;
  left: 13px;
  bottom: 2px;
  padding: 0 5px;

  img {
    height: 22px;
    width: 15px;
    margin-right: 8.5px;
    margin-bottom: 5px;
  }

  span {
    width: 146.5px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 12px;
    letter-spacing: 0.12px;
    font-weight: bold;
  }
`
