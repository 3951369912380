import React from 'react'
import styled from 'styled-components'
import Banner from '../../Components/Royal/Banner/Banner'
// import LeftMenu from "../../Components/LeftMenu";

const RoyalBanner = ({ match, location }) => {
  // const query = queryString.parse(location.search);

  return (
    <Wrapper>
      <Banner />
    </Wrapper>
  )
}

export default RoyalBanner

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`
