import React, { useState } from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'

const PromptEdit = props => {
  const {
    model,
    setModel,
    data,
    setData,
    devData,
    setDevData,
    updateClaudePrompt,
    updateDevClaudePrompt,
  } = props
  const [edit, setEdit] = useState(false)
  const [devEdit, setDevEdit] = useState(false)

  return (
    <div style={{ minHeight: '100%' }}>
      <section className="content-header">
        <h1>Claude Prompt 관리</h1>
        <ol className="breadcrumb">
          <li>
            <a href="/Claude">
              <i className="fa fa-dashboard"></i>Claude
            </a>
          </li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <section className="col-lg-12 connectedSortable">
            <div className="box box-info">
              <div
                className="box-header with-border"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <DropdownButton
                  variant="dark"
                  id="commit-status-dropdown"
                  title={model}
                >
                  {['sonnet', 'haiku'].map((m, i) => {
                    return (
                      <Dropdown.Item key={i} onClick={() => setModel(m)}>
                        {m}
                      </Dropdown.Item>
                    )
                  })}
                </DropdownButton>
              </div>
              <div className="box-body" style={{ display: 'flex' }}>
                <section className="col-lg-6">
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ width: '100%' }}>Dev</div>
                      <div>
                        {devEdit && (
                          <button
                            className="btn btn-default"
                            onClick={async () => {
                              setDevEdit(false)
                            }}
                            style={{ marginRight: '10px' }}
                          >
                            Cancel
                          </button>
                        )}
                        <button
                          className="btn btn-default"
                          onClick={async () => {
                            if (devEdit) {
                              await updateDevClaudePrompt(
                                devData.seq,
                                devData.prompt
                              )
                              await setDevEdit(false)
                            } else {
                              setDevEdit(true)
                            }
                          }}
                        >
                          {devEdit ? 'Save' : 'Edit'}
                        </button>
                      </div>
                    </div>
                    <textarea
                      className="form-control"
                      rows="40"
                      value={devData.prompt}
                      onChange={e =>
                        setDevData({ ...devData, prompt: e.target.value })
                      }
                      disabled={!devEdit}
                    />
                  </div>
                </section>
                <section className="col-lg-6">
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ width: '100%' }}>Live</div>
                      <div>
                        {edit && (
                          <button
                            className="btn btn-default"
                            onClick={async () => {
                              setEdit(false)
                            }}
                            style={{ marginRight: '10px' }}
                          >
                            Cancel
                          </button>
                        )}
                        <button
                          className="btn btn-default"
                          onClick={async () => {
                            if (edit) {
                              await updateClaudePrompt(data.seq, data.prompt)
                              await setEdit(false)
                            } else {
                              setEdit(true)
                            }
                          }}
                        >
                          {edit ? 'Save' : 'Edit'}
                        </button>
                      </div>
                    </div>
                    <textarea
                      className="form-control"
                      rows="40"
                      value={data.prompt}
                      onChange={e =>
                        setData({ ...data, prompt: e.target.value })
                      }
                      disabled={!edit}
                    />
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  )
}

export default PromptEdit
