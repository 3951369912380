import React, { useState, useEffect, useCallback, useMemo } from "react";
import { withRouter } from "react-router-dom";

import Loading from "../Common/Loading/Loading";
import SelectBox from "../Common/SelectBox2";
import { set } from "lodash";

const assistantCreate = (props) => {

    const { data } = props.location.state;

    const [assistantId,         setAssistantId]     = useState(data ? data.assistantId : '');
    const [assistantName,       setAssistantName]   = useState(data ? data.assistantName : '');
    const [instructions,        setInstructions]    = useState(data ? data.instructions : '');
    const [model,               setModel]           = useState(data ? data.model : '');
    const [models,              setModels]          = useState(props.location.state.models);
    const [toolsList,           setToolsList]       = useState(['file_search']);
    const [artistId,            setArtistId]        = useState(data ? data.artistId : '');
    const [mainColor,           setMainColor]       = useState(data ? data.mainColor : '');
    const [subColor,            setSubColor]        = useState(data ? data.subColor : '');
    const [lineColor,           setLineColor]       = useState(data ? data.lineColor : '');
    const [intro,               setIntro]           = useState(data ? data.intro : '');
    const [category,            setCategory]        = useState(data ? data.category : '');
    const [profileImg,          setProfile]         = useState(null);
    const [assistantImg,        setAssistantImg]    = useState(null);
    const [profileImgUrl,       setProfileImgUrl]   = useState(data ? data.profileImg : '');
    const [assistantImgUrl,     setAssistantImgUrl] = useState(data ? data.assistantImg : '');
    const [createUser,          setCreateUser]      = useState(data ? data.createUserEmail : 'ps7izuko@luravel.com');
    const [status,              setStatus]          = useState(data ? data.assistantStatus : 'Y');
    const [gender,              setGender]          = useState(data ? data.gender : '');

    const emailList = [
        'ps7izuko@luravel.com',
        'wbkita@luravel.com',
        'lydia3601@luravel.com',
        'aolst@luravel.com',
        'totomkio@luravel.com',
        'sasaki7@luravel.com',
        'j.jun@luravel.com',
        'narunaru@luravel.com',
        'tonyk@luravel.com',
        'kyu451@luravel.com',
        'kzhiro@luravel.com',
        'shokujm@luravel.com',
        'gibaba@luravel.com',
        'sansour@luravel.com',
        'kxwxsto@luravel.com',
        'hiro62@luravel.com',
        'jjmh3@luravel.com',
        'danshoto@luravel.com',
        'kkhnn@luravel.com',
        'baktam0072@luravel.com',
    ]

    const onChange = (model) => {
        setModel(model);
    }

    const save = async () => {

        const formData = new FormData();

        formData.append('assistantName', assistantName);
        formData.append('instructions', instructions);
        formData.append('model', model);
        formData.append('toolsList', toolsList);
        formData.append('artistId', artistId);
        formData.append('mainColor', mainColor);
        formData.append('subColor', subColor);
        formData.append('lineColor', lineColor);
        formData.append('intro', intro);
        formData.append('category', category);
        formData.append('profileImg', profileImg);
        formData.append('assistantImg', assistantImg);
        formData.append('email', createUser);
        formData.append('status', status)
        formData.append('gender', gender)


        const response = await fetch(`https://api.stipop.com/v4.0.1/assistant`, {
            headers : {
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token"),
                apikey: 'a590539c92b911d3ed83f1be7db9f7d0'
            },
            method:'post',
            body: formData
        });

        const responseJson = await response.json();

        if (responseJson.header.status === 'success') {
            alert('success');
            props.history.push('/Assistant');
        } else {
            alert('fail');
        }

    }

    const update = async () => { 
    
        const formData = new FormData();

        formData.append('assistantName', assistantName);
        formData.append('instructions', instructions);
        formData.append('model', model);
        formData.append('toolsList', toolsList);
        formData.append('artistId', artistId);
        formData.append('mainColor', mainColor);
        formData.append('subColor', subColor);
        formData.append('lineColor', lineColor);
        formData.append('intro', intro);
        formData.append('category', category);
        formData.append('profileImg', profileImg);
        formData.append('assistantImg', assistantImg);
        formData.append('email', createUser);
        formData.append('status', status);
        formData.append('gender', gender);

        const response = await fetch(`https://api.stipop.com/v4.0.1/assistant/${assistantId}`, {
            headers : {
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                token: sessionStorage.getItem("token"),
                apikey: 'a590539c92b911d3ed83f1be7db9f7d0'
            },
            method:'put',
            body: formData
        });

        const responseJson = await response.json();

        if (responseJson.header.status === 'success') {
            alert('success');
            props.history.push('/Assistant');
        } else {
            alert('fail');
        }
    }

    const [Select, setState] = SelectBox(model, models, onChange);

    return (
        <>

            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                assistantName:    <input type={'text'} style={{width:300}} value={assistantName} onChange={e => setAssistantName(e.target.value)} />
            </div>

            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                Instructions: 
                <div>
                    <textarea type={'text'} style={{width:500, height:300}} value={instructions} onChange={e => setInstructions(e.target.value)} />
                </div>
            </div>

            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                {
                    !data && Select
                }
            </div>

            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                create user: <select value={createUser} onChange={e => setCreateUser(e.target.value)}>
                    {createUser && emailList.indexOf(createUser) < 0 ? 
                        <option value={createUser}>{createUser}</option> 
                        : emailList.map((email, index) => (
                            <option key={index} value={email}>{email}</option>
                        ))
                    }
                </select>    
            </div>

            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                artistId:    <input type={'text'} style={{width:300}} value={artistId} onChange={e => setArtistId(e.target.value)} />
            </div>

            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                mainColor: <input type={'text'} style={{width:300}} value={mainColor} onChange={e => setMainColor(e.target.value)} />
            </div>
            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                subColor: <input type={'text'} style={{width:300}} value={subColor} onChange={e => setSubColor(e.target.value)} />
            </div>
            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                lineColor: <input type={'text'} style={{width:300}} value={lineColor} onChange={e => setLineColor(e.target.value)} />
            </div>
            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                intro: <input type={'text'} style={{width:300}} value={intro} onChange={e => setIntro(e.target.value)} />
            </div>
            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                category: <input type={'text'} style={{width:300}} value={category} onChange={e => setCategory(e.target.value)} />
            </div>
            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                gender: <input type={'text'} style={{width:300}} value={gender} onChange={e => setGender(e.target.value)} />
            </div>
            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                profileImg: <input type="file" style={{width: '50%'}} onChange={(event) => {
                    const file = event.target.files[0];
                    const reader = new FileReader();
                    
                    reader.onloadend = () => {
                        setProfileImgUrl(reader.result);
                        setProfile(file);
                    }
                    if (file) {
                        reader.readAsDataURL(file);
                    }
                }}></input>
                {
                    profileImgUrl && <img src={profileImgUrl} style={{width: 100}} />
                }
            </div>
            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                assistantImg: <input type="file" style={{width: '50%'}} onChange={(event) => {
                    const file = event.target.files[0];
                    const reader = new FileReader();
                    
                    reader.onloadend = () => {
                        setAssistantImgUrl(reader.result);
                        setAssistantImg(file);
                    }
                    if (file) {
                        reader.readAsDataURL(file);
                    }
                }} ></input>
                {
                    assistantImgUrl && <img src={assistantImgUrl} style={{width: 300}} />
                }
            </div>

            <div style={{textAlign: 'left', marginBottom: '10px'}}>
                <button onClick={data ? update:save}>{data ? '수정':'저장'}</button>
            </div>

        </>
    )
};

export default withRouter(assistantCreate);

