import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import ChatContentView from '../../Components/AI/ChatContent'

const ChatContent = () => {
  const [type, setType] = useState('sticker')
  const [artistId, setArtistId] = useState('')
  const [contentList, setContentList] = useState([])
  const [uploadList, setUploadList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [contentLoading, setContentLoading] = useState(0)

  useEffect(() => {
    clickSearch()
  }, [type])

  const clickSearch = async () => {
    await setIsLoading(true)
    await setUploadList([])
    await axios
      .get(`/new/webAdmin/v2/artist/contents/${artistId}?type=${type}`, {
        headers: { token: sessionStorage.getItem('token') },
      })
      .then(({ data }) => {
        setContentList(data.result)
        setIsLoading(false)
      })
  }

  const updateTag = (id, tags) => {
    const data = contentList.map((content) => {
      if (content.contentId === id) {
        return { ...content, contentTag: tags }
      }
      return content
    })

    setContentList(data)
  }

  const updateShare = (id, share) => {
    const data = contentList.map((content) => {
      if (content.contentId === id) {
        return { ...content, contentShare: share }
      }
      return content
    })

    setContentList(data)
  }

  const clickSave = async (content) => {
    if (contentLoading === content.contentId) return

    await setContentLoading(content.contentId)
    await axios
      .post(`/new/webAdmin/v2/artist/contents?type=${type}`, content, {
        headers: { token: sessionStorage.getItem('token') },
      })
      .then(() => {
        // clickSearch()
        setContentLoading(0)
      })
  }

  const clickDelete = async (content) => {
    if (contentLoading === content.contentSeq) return

    await setContentLoading(content.contentSeq)
    await axios
      .delete(`/new/webAdmin/v2/artist/contents/${content.contentSeq}`, {
        headers: { token: sessionStorage.getItem('token') },
      })
      .then(() => {
        // clickSearch()
        setContentList(
          contentList.filter((item) => item.contentSeq !== content.contentSeq)
        )
        setContentLoading(0)
      })
  }

  const exportData = () => {
    let headers = [
      'Content tags',
      'When you can share the Content ID',
      'Content ID',
    ]

    const csv = [
      headers.join(','),
      ...contentList.map((content) => {
        return [
          '"' + content.contentTag + '"',
          '"' + content.contentShare + '"',
          content.contentId,
        ].join(',')
      }),
    ].join('\r\n')

    const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const csvUrl = URL.createObjectURL(csvBlob)
    const link = document.createElement('a')
    link.href = csvUrl
    link.setAttribute('download', 'contentList.csv')
    link.click()
  }

  return (
    <Wrapper>
      <ChatContentView
        type={type}
        setType={setType}
        artistId={artistId}
        setArtistId={setArtistId}
        contentList={contentList}
        clickSearch={clickSearch}
        updateTag={updateTag}
        updateShare={updateShare}
        clickSave={clickSave}
        clickDelete={clickDelete}
        uploadList={uploadList}
        setUploadList={setUploadList}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        exportData={exportData}
        contentLoading={contentLoading}
      />
    </Wrapper>
  )
}

export default ChatContent

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
