import React, { useRef } from 'react'
import styled from 'styled-components'

const Popup = props => {
  const {
    version,
    popup,
    setPopup,
    copyLangList,
    setCopyLangList,
    copyVersion,
  } = props

  const langList = [
    { code: 'en', lang: 'English' },
    { code: 'ko', lang: 'Korean' },
    { code: 'ja', lang: 'Japaness' },
  ]

  const boxRef = useRef()

  return (
    <Wrapper
      onClick={e => {
        if (boxRef.current && !boxRef.current.contains(e.target)) {
          setPopup('')
          setCopyLangList([version.lang])
        }
      }}
    >
      <Box ref={boxRef}>
        <Header>버전 복제</Header>
        <Body>
          <div>복제할 언어를 선택해 주세요.</div>
          <div style={{ display: 'flex' }}>
            {langList.map((item, i) => (
              <Button
                key={i}
                onClick={() =>
                  setCopyLangList(prev =>
                    prev.includes(item.code)
                      ? prev.filter(p => p !== item.code)
                      : prev.concat(item.code)
                  )
                }
                selected={copyLangList.includes(item.code)}
              >
                {item.lang}
              </Button>
            ))}
          </div>
          {copyLangList.length > 0 && (
            <>
              <SpaceResponsive height={20} />
              <div>{version.version} 버전을 복제하시겠습니까?</div>
              <div>복제한 버전은 새로운 큐레이션에 등록됩니다.</div>
            </>
          )}
        </Body>
        <Footer deactive={copyLangList.length === 0}>
          <button
            id="no"
            onClick={() => {
              setPopup('')
              setCopyLangList([version.lang])
            }}
          >
            취소
          </button>
          <button
            id="yes"
            onClick={() => {
              if (copyLangList.length > 0) {
                copyVersion(version)
              }
            }}
          >
            복제
          </button>
        </Footer>
      </Box>
    </Wrapper>
  )
}

export default Popup

const SpaceResponsive = styled.div`
  height: ${props => props.height}px;
  flex-shrink: 0;
`

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`

const Box = styled.div`
  min-width: 400px;
  min-height: 250px;
  background-color: #fff;
`

const Header = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ecf0f5;
  font-size: 20px;
  font-weight: bold;
  padding: 15px;
  box-sizing: border-box;
`

const Body = styled.div`
  width: 100%;
  min-height: 130px;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Button = styled.button`
  height: 30px;
  border-radius: 5px;
  border: 1px solid ${props => (props.selected ? '#007ffb' : '#000')};
  background-color: ${props => (props.selected ? '#007ffb' : '#fff')};
  color: ${props => (props.selected ? '#fff' : '#000')};
  margin-right: 5px;
`

const Footer = styled.div`
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 60px;
  border-top: 1px solid #ecf0f5;

  #yes {
    background-color: #007ffb;
    color: #fff;
    height: 35px;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    cursor: ${props => props.deactive && 'not-allowed'};
    opacity: ${props => props.deactive && 0.4};

    &:hover {
      opacity: 0.8;
      font-weight: bold;
    }
  }

  #no {
    background-color: #dc143c;
    color: #fff;
    margin-right: 10px;
    height: 35px;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: none;

    &:hover {
      opacity: 0.8;
      font-weight: bold;
    }
  }
`
