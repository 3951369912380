import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const VideoUpload = (props) => {
  const { uploadList, setUploadList } = props

  useEffect(() => {
    console.log(uploadList)
  }, [uploadList])

  const handleUpdate = async (index, key, value) => {
    const updatedList = [...uploadList]
    updatedList[index][key] = value
    setUploadList(updatedList)
  }

  const clickDelete = (index) => {
    const updatedList = [...uploadList]
    updatedList.splice(index, 1)
    setUploadList(updatedList)
  }

  return (
    <Table className="table no-margin">
      {uploadList.length > 0 && (
        <thead>
          <tr>
            <th>No</th>
            <th>Video</th>
            <th>Video name</th>
            <th>Video Tag</th>
            <th>When you can share the Video URL</th>
            <th>Price</th>
            <th></th>
          </tr>
        </thead>
      )}
      {uploadList.length > 0 &&
        uploadList.map((item, index) => (
          <tbody>
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <video controls style={{ width: '100px', height: 'auto' }}>
                  <source src={URL.createObjectURL(item.video)} />
                </video>
              </td>
              <td>
                <input
                  type="text"
                  value={item.name}
                  onChange={(e) => {
                    handleUpdate(index, 'name', e.target.value)
                  }}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={item.tag}
                  onChange={(e) => {
                    handleUpdate(index, 'tag', e.target.value)
                  }}
                />
              </td>
              <td>
                <textarea
                  type="text"
                  value={item.share}
                  onChange={(e) => {
                    handleUpdate(index, 'share', e.target.value)
                  }}
                  style={{ width: '100%' }}
                />
              </td>
              <td>
                <select
                  value={item.saleType}
                  onChange={(e) =>
                    handleUpdate(index, 'saleType', e.target.value)
                  }
                >
                  <option value="free">Free</option>
                  <option value="paid">Paid</option>
                  <option value="premium">Premium</option>
                </select>
              </td>
              <td>
                <button onClick={() => clickDelete(index)}>Delete</button>
              </td>
            </tr>
          </tbody>
        ))}
    </Table>
  )
}

export default VideoUpload

const Table = styled.table`
  td {
    white-space: break-spaces;
  }
`
