import React from 'react'
import styled from 'styled-components'

const AiCard = props => {
  const { title, assistantList, lang } = props

  return (
    <>
      <Title>{title}</Title>
      <SpaceResponsive height={16} />
      <Wrapper>
        <AssistantWrapper>
          {assistantList &&
            assistantList.map((assistant, index) => (
              <AssistantItem key={index} onClick={() => {}}>
                <img
                  src={
                    assistant.assistantProfileImg ||
                    'https://img.stipop.io/image/stipop/icon/chat/chat-create-profile-icon.png'
                  }
                  alt=""
                />
                <AssistantTitle>
                  {assistant.assistantInfo
                    ? assistant.assistantInfo.filter(a => a.lang === lang)[0]
                      ? assistant.assistantInfo.filter(a => a.lang === lang)[0]
                          .assistantName
                      : assistant.assistantName
                    : assistant.assistantName}
                </AssistantTitle>
                <AssistantIntro>
                  {assistant.assistantInfo
                    ? assistant.assistantInfo.filter(a => a.lang === lang)[0]
                      ? assistant.assistantInfo.filter(a => a.lang === lang)[0]
                          .intro
                      : assistant.intro
                    : assistant.intro}
                </AssistantIntro>
              </AssistantItem>
            ))}
        </AssistantWrapper>
      </Wrapper>
      <SpaceResponsive height={16} />
    </>
  )
}

export default AiCard

const SpaceResponsive = styled.div`
  height: ${props => props.height}px;
  flex-shrink: 0;
`

const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  flex-shrink: 0;
`

const AssistantWrapper = styled.div`
  height: 100%;
  display: flex;
  column-gap: 8px;
`

const Title = styled.div`
  height: 22px;
  font-size: 18px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  letter-spacing: 0.21px;
`

const AssistantItem = styled.div`
  min-width: 120px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  word-wrap: break-word;
  word-break: keep-all;

  img {
    width: 120px;
    height: 120px;
    border-radius: 12px;
    object-fit: cover;
  }
`

const AssistantTitle = styled.div`
  width: 100%;
  max-width: 120px;
  font-size: 12px;
  font-family: Poppins;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AssistantIntro = styled.div`
  width: 120px;
  font-size: 10px;
  font-weight: 400;
  font-family: Poppins;
  color: #787878;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
